import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { formatPrice } from '../../../../Charts/Functions'

export function SpesaMensile ({ dbData = [] }) {
  const [tableData, setTableData] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()
  useEffect(() => {
    const elements = []
    const year1Elements = dbData.filter(({ Anno }) => Anno === year1)
    const year2Elements = dbData.filter(({ Anno }) => Anno === year2)

    year1Elements.forEach(({ Mese, SpesaComplessiva, Quantita }) => {
      const year2Element = year2Elements.find(({ Mese: Mese2 }) => Mese === Mese2)
      elements.push({
        Anno: year1,
        Mese,
        SpesaComplessiva,
        SpesaComplessiva2: year2Element?.SpesaComplessiva,
        DeltaSpesa: (SpesaComplessiva - year2Element?.SpesaComplessiva).toFixed(2),
        DeltaSpesaPercentuale: year2Element?.SpesaComplessiva ? ((SpesaComplessiva - year2Element?.SpesaComplessiva) / year2Element?.SpesaComplessiva * 100).toFixed(2) : 0
      })
    })
    elements.push({
      Anno: year1,
      Mese: '00_Totale',
      SpesaComplessiva: year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      SpesaComplessiva2: year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      DeltaSpesa: (year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)).toFixed(2),
      DeltaSpesaPercentuale: year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) ? ((year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)) / year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) * 100).toFixed(2) : 0
    })

    setTableData(elements)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`Analisi Spesa Mensile ${year1} vs ${year2}`}
            subTitle='La lista dei mesi con il costo totale per anno.'
          />
          <Table tableData={tableData} years={[year1, year2]} />
          <Chart data={dbData} />
        </Flex>
      </div>
    </>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Mese</th>
          <th>Spesa Totale {year1}</th>
          <th>Spesa Totale {year2}</th>
          <th>Delta Spesa</th>
          <th>Delta Spesa %</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Anno, Mese, SpesaComplessiva, Quantita, SpesaComplessiva2, Quantita2, DeltaSpesa, DeltaQuantita, DeltaSpesaPercentuale, DeltaQuantitaPercentuale }, key) =>
          <tr key={key} style={{ fontWeight: Mese?.split('_')[1] === 'Totale' ? 'bold' : 'normal' }}>
            <td>{Mese?.split('_')[1]}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva2)}</td>
            <td>{formatPrice(DeltaSpesa)}</td>
            <td style={{ backgroundColor: DeltaSpesaPercentuale > 0 ? '#80dc7282' : '#dc728080', color: DeltaSpesaPercentuale > 0 ? '#0f6e0f' : '#6e0f0f' }}>{DeltaSpesaPercentuale} %</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
