import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { formatPrice } from '../../../../Charts/Functions'

export function TariffaMediaPerTipoBiglietteriaClasse ({ dbData = [] }) {
  const [tableDataA, setTableDataA] = useState([])
  const [tableDataF, setTableDataF] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()
  useEffect(() => {
    const getElements = (tipoBiglietteria) => {
      const elements = []
      const year1Elements = dbData.filter(({ Anno }) => Anno === year1).filter(({ TipoBiglietteria }) => TipoBiglietteria === tipoBiglietteria)
      const year2Elements = dbData.filter(({ Anno }) => Anno === year2).filter(({ TipoBiglietteria }) => TipoBiglietteria === tipoBiglietteria)

      year1Elements.forEach(({ NazConInt, Classe, SpesaTotale, Quantita, PrezzoMedio }) => {
        const year2Element = year2Elements.find(({ NazConInt: NazConInt2, Classe: Classe2 }) => NazConInt === NazConInt2 && Classe === Classe2)
        elements.push({
          Anno: year1,
          NazConInt,
          Classe,
          SpesaTotale,
          Quantita,
          PrezzoMedio: parseFloat(PrezzoMedio)?.toFixed(2),
          SpesaTotale2: year2Element?.SpesaTotale,
          Quantita2: year2Element?.Quantita,
          PrezzoMedio2: parseFloat(year2Element?.PrezzoMedio)?.toFixed(2),
          percentage: year2Element?.PrezzoMedio ? ((PrezzoMedio - year2Element?.PrezzoMedio) / year2Element?.PrezzoMedio * 100).toFixed(2) : 0,
          percentageQuantita: year2Element?.Quantita ? ((Quantita - year2Element?.Quantita) / year2Element?.Quantita * 100).toFixed(2) : 0
        })
      })

      elements.push({
        Anno: year1,
        NazConInt: 'Totale',
        Classe: 'Totale',
        SpesaTotale: year1Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0).toFixed(2),
        Quantita: year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0),
        PrezzoMedio: (year1Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0) / year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(2),
        SpesaTotale2: year2Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0),
        Quantita2: year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0),
        PrezzoMedio2: (year2Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0) / year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(2),
        percentage: year2Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0) ? (((year1Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0) / year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) - (year2Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0) / year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0))) / (year2Elements.reduce((acc, { SpesaTotale }) => acc + parseFloat(SpesaTotale), 0) / year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) * 100).toFixed(2) : 0,
        percentageQuantita: ((year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0) - year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) / year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0) * 100).toFixed(2)
      })
      return elements
    }

    setTableDataA(getElements('A'))
    setTableDataF(getElements('F'))
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`Voli - Report Spesa per Tipo Biglietteria e Classe ${year1} vs ${year2}`}
            subTitle='La lista delle classi e dei tipi di biglietteria, con il costo totale e la tariffa media per tipo biglietteria e classe.'
          />
          <Table tableData={tableDataA} years={[year1, year2]} />
          <Chart data={dbData} tipoBiglietteria='A' />
        </Flex>
      </div>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`Treni - Report Spesa per Tipo Biglietteria e Classe ${year1} vs ${year2}`}
            subTitle='La lista delle classi e dei tipi di biglietteria, con il costo totale e la tariffa media per tipo biglietteria e classe.'
          />
          <Table tableData={tableDataF} years={[year1, year2]} />
          <Chart data={dbData} tipoBiglietteria='F' />
        </Flex>
      </div>
    </>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Tipo Biglietteria</th>
          <th>Classe</th>
          <th>Spesa Totale {year1}</th>
          <th>Quantità {year1}</th>
          <th>Tariffa Media {year1}</th>
          <th>Spesa Totale {year2}</th>
          <th>Quantità {year2}</th>
          <th>Tariffa Media {year2}</th>
          <th>Delta Spesa %</th>
          <th>Delta Quantità %</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Anno, NazConInt, Classe, SpesaTotale, Quantita, PrezzoMedio, SpesaTotale2, Quantita2, PrezzoMedio2, percentage, percentageQuantita }, key) =>
          <tr key={key} style={{ fontWeight: NazConInt === 'Totale' ? 'bold' : 'normal' }}>
            <td>{NazConInt}</td>
            <td>{Classe}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaTotale)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita}</td>
            <td>{PrezzoMedio}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaTotale2)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita2}</td>
            <td>{formatPrice(PrezzoMedio2)}</td>
            <td style={{ backgroundColor: percentage > 0 ? '#80dc7282' : '#dc728080', color: percentage > 0 ? '#0f6e0f' : '#6e0f0f' }}>
              {percentage} %
            </td>
            <td style={{ backgroundColor: percentageQuantita > 0 ? '#80dc7282' : '#dc728080', color: percentageQuantita > 0 ? '#0f6e0f' : '#6e0f0f' }}>
              {percentageQuantita} %
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
