import React, { useEffect } from 'react'
import { useAuth } from '../../Provider/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { getUrlParams } from '../../Services/Functions'
import { Flex, TabHeader, Tabs } from '../../Components'
import { TabUsers } from './TabUsers'

const getTabs = () => [
  { id: 1, icon: 'edit-2', title: 'Elenco Utenti', content: <TabUsers /> }
]

export function Users () {
  const { user: { user } } = useAuth()
  const tab = getUrlParams('tab')
  const navigate = useNavigate()
  const currentTab = parseInt(tab) || 0

  useEffect(() => {
    user?.username !== 'admin' && navigate('/home')
  }, [])

  return (
    <Flex fw>
      <Tabs
        style={{ width: '100%', height: '100%', padding: 20 }}
        onTabSelection={(e) => navigate('/users/list?tab=' + e)}
        currentTab={currentTab}
      >
        {getTabs()?.map((tab, index) => (
          <TabHeader title={tab?.title} key={index} index={index} icon={tab?.icon}>
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
    </Flex>
  )
}
