import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Button, Flex, Modal, Text } from '../../../Components'
import { useState } from 'react'

export const ClientsTable = ({ clientsList: [clientsList, setClientsList] }) => {
  const getEditor = ({ value, editorCallback, rowData: { codCliente12 } }) => (
    <InputText
      type='text' value={value} onChange={({ target: { value } }) => {
        const newClientsList = [...clientsList]
        newClientsList[clientsList.findIndex((client) => client.codCliente12 === codCliente12)].email = value
        setClientsList(newClientsList)
        editorCallback(value)
      }}
    />)

  return (
    <DataTable
      style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
      value={clientsList} emptyMessage='Nessun cliente' responsiveLayout='scroll' paginator rows={10}
    >
      <Column field='codCliente12' {...columnProps('Codice')} />
      <Column field='nome' {...columnProps('Nome')} />
      <Column field='cognome' {...columnProps('Cognome')} />
      <Column field='ragioneSociale' {...columnProps('Ragione sociale')} />
      <Column field='email' editable {...columnProps('Email')} editor={getEditor} />
      <Column body={props => <BtnActions {...props} reportType='business' list={clientsList} updateList={setClientsList} />} />
    </DataTable>
  )
}

const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

const BtnActions = ({ codCliente12, list, updateList }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const onConfirmRemove = () => {
    updateList(list.filter((client) => client.codCliente12 !== codCliente12))
  }
  return (
    <Flex width={280} row>
      <Modal visible={modalVisible} header='Rimozione cliente' noDismissable hideClose onHide={() => setModalVisible(false)}>
        <Flex fw fh style={{ padding: 20 }}>
          <Text value='Vuoi rimuovere questo cliente?' />
          <Flex fw row jb style={{ marginTop: 20 }}>
            <Button label='Annulla' icon='bin' onClick={() => setModalVisible(false)} />
            <Button label='Conferma' icon='check' onClick={onConfirmRemove} />
          </Flex>
        </Flex>
      </Modal>
      <Button label='Rimuovi' icon='bin' onClick={() => setModalVisible(true)} />
    </Flex>
  )
}
