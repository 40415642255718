import React from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Chart } from './Chart'
import { Button, Flex } from '../../../Components'
import { TextEditor } from '../ReportEditor/Functions'
import { tipoBiglietteriaOptions } from '../../../Services/Functions'

export function TariffaMediaPerTipoBiglietteriaClasse ({ tableData: [tableData, setTableData] }) {
  const onRowEditComplete = ({ newData, index }) => {
    const _products = [...tableData]
    _products[index] = newData
    setTableData(_products)
  }

  const [year1 = '', year2 = ''] = tableData.map(({ Anno }) => Anno).filter((value, index) => tableData.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()
  return (
    <Flex fw>
      <DataTable
        value={tableData} style={{ width: '100%' }} onRowEditComplete={onRowEditComplete} editMode='row'
        header={`Report Spesa  ${year1} vs ${year2}`} paginator rows={10} rowsPerPageOptions={[5, 10, 20]}
      >
        <Column field='Anno' header='Anno' />
        <Column field='NazConInt' header='Nazionale/Internazionale/Intercontinentale' />
        <Column field='Classe' header='Classe' editor={(options) => TextEditor(options)} />
        <Column field='SpesaTotale' header='Spesa Totale' editor={(options) => TextEditor(options, true)} />
        <Column field='Quantita' header='Quantità' editor={(options) => TextEditor(options, true)} />
        <Column field='PrezzoMedio' header='Prezzo medio' editor={(options) => TextEditor(options, true)} sortable />
        <Column field='TipoBiglietteria' header='Tipo Biglietteria' body={(rowData) => tipoBiglietteriaOptions.find(({ value }) => value === rowData.TipoBiglietteria)?.label} editor={(options) => TextEditor(options)} />
        <Column rowEditor bodyStyle={{ textAlign: 'center' }} />
        <Column body={(rowData, { rowIndex }) => <Button round icon='bin' onClick={() => setTableData(tableData.filter((_, i) => i !== rowIndex))} />} bodyStyle={{ textAlign: 'center' }} />
      </DataTable>
      <hr style={{ width: '100%', border: '1px solid black', margin: 20 }} />
      <Chart data={tableData} tipoBiglietteria='A' />
      <Chart data={tableData} tipoBiglietteria='F' />
    </Flex>
  )
}
