import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import { useCQuery } from '../../../Services'
import { Button, Flex, Modal, Spinner, Text } from '../../../Components'
import { columnProps, deleteReport } from './Functions'

// const tipoBiglietteriaOptions = [{ label: 'Tutto', value: '' }, { label: 'Aerea', value: 'A' }, { label: 'Ferroviaria', value: 'F' }, { label: 'Varia', value: 'V' }]
const monthsOpt = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

export default function TabReports () {
  const { data: reports = [], isSuccess } = useCQuery(['reports'])
  const [reportsTable, setReportsTable] = useState(reports)
  const navigate = useNavigate([])

  useEffect(() => {
    setReportsTable(reports.map((r) => {
      const { reportFilter: { year, months, /* tipoBiglietteria */ clients, filterClients }, reportsList = [] } = r
      return {
        ...r,
        year: year,
        months: `${monthsOpt[months[0]]} - ${monthsOpt[months[1]]}`,
        clients: filterClients ? `${clients?.length} selezionati` : 'Tutti',
        // tipoBiglietteria: tipoBiglietteriaOptions.find((t) => t.value === tipoBiglietteria)?.label,
        numeroPagine: reportsList.length
      }
    }))
  }, [reports])

  const BtnActions = ({ _id }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex width={160} row>
        <Modal visible={modalVisible} header='Cancellazione Report'>
          <Flex fw>
            <Text value='Vuoi cancellare questo report?' />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='bin' onClick={() => setModalVisible(false)} />
              <Button label='Conferma' icon='check' onClick={() => deleteReport(_id)} />
            </Flex>
          </Flex>
        </Modal>
        <Button round tooltip='Modifica' icon='edit' onClick={() => navigate('/report/' + _id)} />
        <Button style={{ marginLeft: 20 }} round tooltip='Elimina' icon='bin' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh>
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/report/new')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={reportsTable} emptyMessage='Nessun report creato' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='name' {...columnProps('Nome')} />
        <Column field='year' {...columnProps('Anno')} />
        <Column field='months' {...columnProps('Mesi')} />
        {/* <Column field='tipoBiglietteria' {...columnProps('Tipo Biglietteria')} /> */}
        <Column field='clients' {...columnProps('Clienti')} />
        <Column field='numeroPagine' {...columnProps('Numero Pagine')} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
