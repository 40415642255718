import React, { useState } from 'react'
import { Button, Flex } from '..'
import { FC } from '../../Services'
import { ClientCodeSearch, ClientFieldSearch, SearchedClients } from './Components'

export function SearchClient ({ onSelectClients, hideModal }) {
  const [isSearching, setIsSearching] = useState(false)
  const [clients, setClients] = useState([])
  const [selectedClients, setSelectedClients] = useState([])
  const [showClientsResults, setShowClientsResults] = useState(false)
  const [searchType, setSearchType] = useState('clientData')

  const loadClients = async (querySearch) => {
    setIsSearching(true)
    const res = await FC.service('biDB').get('searchClient', { query: querySearch })
    setIsSearching(false)
    if (!res?.ok) return
    setClients((res?.data || []).map(({
      datiGeneraliCliente: { codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email },
      datiContabiliCliente: { codiceFiscale }
    }) => ({ codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email, codiceFiscale })))
    setShowClientsResults(true)
    setSelectedClients([])
  }

  const loadClientsByCode = async (code) => {
    setIsSearching(true)
    const res = await FC.service('biDB').get('searchClient', { query: { codCliente12: code } })
    setIsSearching(false)
    if (!res?.ok) return
    setClients((res?.data || []).map(({
      datiGeneraliCliente: { codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email },
      datiContabiliCliente: { codiceFiscale }
    }) => ({ codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email, codiceFiscale })))
    setShowClientsResults(true)
    setSelectedClients([])
  }

  const selectClients = (clients) => {
    onSelectClients(clients)
    setSelectedClients([])
    clients?.length
      ? window.growl.show({ severity: 'success', summary: 'Clienti aggiunti', detail: 'I clienti sono stati aggiunti correttamente' })
      : window.growl.show({ severity: 'success', summary: 'Cliente aggiunto', detail: 'Il cliente è stato aggiunto correttamente' })
  }

  const onCleanUp = () => {
    setClients([])
    setIsSearching(false)
    setSelectedClients([])
    setShowClientsResults(false)
  }

  const changeSearchType = (type) => () => {
    setClients([])
    setIsSearching(false)
    setSelectedClients([])
    setShowClientsResults(false)
    setSearchType(type)
  }

  return (
    <Flex fw fh js>
      {!searchType && (
        <Flex fw fh row>
          <Button icon='lens' label='Ricerca per codice cliente' onClick={changeSearchType('clientCode')} style={{ marginLeft: 10, marginRight: 10 }} />
          <Button icon='lens' label='Ricerca per dati anagrafici cliente' onClick={changeSearchType('clientData')} style={{ marginLeft: 10, marginRight: 10 }} />
        </Flex>
      )}
      {searchType === 'clientCode' && <ClientCodeSearch isSearching={isSearching} searchClient={loadClientsByCode} onCleanUp={onCleanUp} changeSearchType={changeSearchType} />}
      {searchType === 'clientData' && <ClientFieldSearch isSearching={isSearching} searchClient={loadClients} onCleanUp={onCleanUp} changeSearchType={changeSearchType} />}
      <SearchedClients isSearching={isSearching} clients={clients} selectedClients={[selectedClients, setSelectedClients]} showClientsResults={showClientsResults} />
      {!!selectedClients?.length && (
        <Flex fw style={{ padding: 20, border: '1px solid pink' }}>
          <Button label={`Conferma ${selectedClients?.length} clienti`} icon='check' onClick={() => selectClients(selectedClients)} />
        </Flex>
      )}
    </Flex>
  )
}
