import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { tipoBiglietteriaOptions } from '../../../../Services/Functions'
import { formatPrice } from '../../../../Charts/Functions'

export function SpesaPerVettore ({ dbData = [], tipoBiglietteria }) {
  const [tableData, setTableData] = useState([])
  const [dataForChart, setDataForChart] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()
  useEffect(() => {
    const first20Year1Elements = []
    const first20Year2Elements = []
    const allYear1Elements = dbData.filter(({ Anno }) => Anno === year1).sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)
    const allYear2Elements = dbData.filter(({ Anno }) => Anno === year2).sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)
    const totalSpesaComplessivaYear1 = allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)

    allYear1Elements.forEach((y1El) => {
      const y2El = allYear2Elements.find(({ Vettore: c }) => c === y1El?.Vettore)
      if (!first20Year1Elements.includes(y1El?.Vettore) && first20Year1Elements.length < 20) {
        first20Year1Elements.push(y1El)
        first20Year2Elements.push(y2El || { ...y1El, Anno: year2, SpesaComplessiva: '0' })
      }
    })
    setDataForChart([...first20Year1Elements, ...first20Year2Elements])
    const elements = []
    first20Year1Elements.forEach(({ Vettore, SpesaComplessiva, Quantita }) => {
      const year2Element = first20Year2Elements.find(({ Vettore: Vettore2 }) => Vettore === Vettore2)
      elements.push({
        Anno: year1,
        Vettore,
        SpesaComplessiva,
        Incidenza: ((SpesaComplessiva / totalSpesaComplessivaYear1) * 100).toFixed(2),
        SpesaComplessiva2: year2Element?.SpesaComplessiva,
        DeltaSpesaPercentage: ((SpesaComplessiva - year2Element?.SpesaComplessiva) / year2Element?.SpesaComplessiva * 100).toFixed(2)
      })
    })
    elements.push({
      Anno: year1,
      Vettore: 'Altri',
      SpesaComplessiva: allYear1Elements.filter(({ Vettore }) => !first20Year1Elements.map(({ Vettore }) => Vettore).includes(Vettore)).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      Incidenza: (allYear1Elements.filter(({ Vettore }) => !first20Year1Elements.map(({ Vettore }) => Vettore).includes(Vettore)).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / totalSpesaComplessivaYear1 * 100).toFixed(2),
      SpesaComplessiva2: allYear2Elements.filter(({ Vettore }) => !first20Year2Elements.map(({ Vettore }) => Vettore).includes(Vettore)).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      DeltaSpesaPercentage: ((allYear1Elements.filter(({ Vettore }) => !first20Year1Elements.map(({ Vettore }) => Vettore).includes(Vettore)).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - allYear2Elements.filter(({ Vettore }) => !first20Year2Elements.map(({ Vettore }) => Vettore).includes(Vettore)).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)) / allYear2Elements.filter(({ Vettore }) => !first20Year2Elements.map(({ Vettore }) => Vettore).includes(Vettore)).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) * 100).toFixed(2)
    })
    elements.push({
      Anno: year1,
      Vettore: 'Totale',
      SpesaComplessiva: allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      Incidenza: (allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / totalSpesaComplessivaYear1 * 100).toFixed(2),
      SpesaComplessiva2: allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      DeltaSpesaPercentage: ((allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)) / allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) * 100).toFixed(2)
    })
    setTableData(elements)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`${tipoBiglietteriaOptions.find(({ value }) => value === tipoBiglietteria)?.label} - Analisi Spesa per Vettore ${year1} vs ${year2}`}
            subTitle="La lista dei vettori più frequenti con il costo totale, l'incidenza e la variazione percentuale della spesa per anno."
          />
          <Table tableData={tableData} years={[year1, year2]} />
          {tipoBiglietteria === 'F' && <Chart data={dataForChart} tipoBiglietteria={tipoBiglietteria} />}
        </Flex>
      </div>
      {tipoBiglietteria === 'A' && <Chart data={dataForChart} tipoBiglietteria={tipoBiglietteria} />}
    </>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Vettore</th>
          <th>Spesa Totale {year1}</th>
          <th>Incidenza %</th>
          <th>Spesa Totale {year2}</th>
          <th>Delta Spesa %</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Anno, Vettore, SpesaComplessiva, SpesaComplessiva2, Incidenza, DeltaSpesaPercentage }, key) =>
          <tr key={key} style={{ fontWeight: Vettore === 'Totale' ? 'bold' : 'normal' }}>
            <td>{Vettore}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80dc7282', color: '#0f6e0f' }}>{Incidenza} %</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva2)}</td>
            <td style={{ backgroundColor: DeltaSpesaPercentage > 0 ? '#80dc7282' : '#dc728080', color: DeltaSpesaPercentage > 0 ? '#0f6e0f' : '#6e0f0f' }}>{DeltaSpesaPercentage} %</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
