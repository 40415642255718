import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Flex } from '../../../../Components'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const barOptions = (title) => ({
  indexAxis: 'y',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right'
    },
    title: {
      display: true,
      text: title
    }
  }
})

export function Chart ({ data = [] }) {
  const [barData, setBarData] = useState()
  const [barData2, setBarData2] = useState()

  useEffect(() => {
    if (!data?.length) return
    let labels = data.map(({ TipoTariffa }) => TipoTariffa)
    labels = labels.filter((value, index) => labels.indexOf(value) === index).sort()
    setBarData({
      labels,
      datasets: [
        {
          label: 'Saving',
          data: labels.map((l) => data?.find(({ TipoTariffa, Anno }) => TipoTariffa === l)?.Saving || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ]
    })
    setBarData2({
      labels,
      datasets: [
        {
          label: 'Miss Saving',
          data: labels.map((l) => data?.find(({ TipoTariffa, Anno }) => TipoTariffa === l)?.MissSaving || '0'),
          borderColor: 'rgb(153, 102, 255)',
          backgroundColor: 'rgba(153, 102, 255, 0.5)'
        }
      ]
    })
  }, [data])

  if (!barData) return null

  return (
    <Flex fw style={{ height: 600 }} row>
      <Bar options={barOptions('Saving')} data={barData} />
      <Bar options={barOptions('Miss Saving')} data={barData2} />
    </Flex>
  )
}
