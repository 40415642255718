import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { Input } from '../../../Components'
import { TariffaMediaPerTipoBiglietteriaClasse, SpesaPerArea, SpesaMensile, SpesaRiemissioni, SpesaPerVettore, SpesaHotelPerDestinazioni, SpesaPerHotel, SpesaPerNoleggi, TopFornitoriNoleggi } from '.'
import { TopDestinazioni } from '../TopDestinazioni'
import { SavingsPerTipoItinerario } from '../SavingsPerTipoItinerario'
import { SavingsPerTipoTariffa } from '../SavingsPerTipoTariffa'
import { SavingsPerMotivazione1 } from '../SavingsPerMotivazione1'
import { TopPassengersBiglietti } from '../TopPassengersBiglietti'

export const useReport = (reportId) => {
  const [report, setReport] = useState()
  const { data, isSuccess } = useCQuery(['report', reportId])

  useEffect(() => {
    isSuccess && setReport(data)
  }, [isSuccess, data])

  const setReportProp = (prop, value) =>
    setReport({ ...report, [prop]: value })

  return [report, setReportProp, isSuccess]
}

export const saveReport = async (report) => {
  if (!report?.name) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
    return false
  }
  if (report._id === 'new') {
    delete report._id
    const res = await FC.service('reports').create(report)
    invalidateQuery(['report', 'reports'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('reports').patch(report._id, report)
  invalidateQuery(['report', 'reports'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

export const TextEditor = ({ value, editorCallback }, isNumber) => {
  return (
    <Input
      id='txt' value={value}
      onChange={({ txt }) => {
        if (isNumber && /[a-zA-Z]/.test(txt)) {
          return
        }
        editorCallback(txt)
      }}
    />
  )
}

export const reportPages = [
  {
    element: <TariffaMediaPerTipoBiglietteriaClasse />,
    name: 'Tariffa media per tipo biglietteria e classe',
    id: 'TariffaMediaPerTipoBiglietteriaClasse',
    query: 'getTariffaMediaPerTipoBiglietteriaClasse',
    category: 'Generale'
  },
  {
    element: <SpesaPerArea />,
    name: 'Spesa per Tipologia di servizio',
    id: 'SpesaPerArea',
    query: 'getSpesaPerArea',
    category: 'Generale'
  },
  {
    element: <SpesaMensile />,
    name: 'Spesa mensile',
    id: 'SpesaMensile',
    query: 'getSpesaMensile',
    category: 'Generale'
  },
  {
    element: <TopDestinazioni />,
    name: 'Top destinazioni',
    id: 'TopDestinazioniVoli',
    query: 'getSpesaTopDestinationsVoli',
    category: 'Voli'
  },
  {
    element: <TopDestinazioni />,
    name: 'Top destinazioni',
    id: 'TopDestinazioniTreni',
    query: 'getSpesaTopDestinationsTreni',
    category: 'Treni'
  },
  {
    element: <SpesaRiemissioni />,
    name: 'Spesa riemissioni',
    id: 'SpesaRiemissioniVoli',
    query: 'getSpesaPerRiemissioniVoli',
    category: 'Voli'
  },
  {
    element: <SpesaPerVettore />,
    name: 'Spesa per vettore',
    id: 'SpesaPerVettoreVoli',
    query: 'getSpesaPerVettoreVoli',
    category: 'Voli'
  },
  {
    element: <SpesaPerVettore />,
    name: 'Spesa per vettore',
    id: 'SpesaPerVettoreTreni',
    query: 'getSpesaPerVettoreTreni',
    category: 'Treni'
  },
  {
    element: <SpesaHotelPerDestinazioni />,
    name: 'Spesa hotel per destinazioni',
    id: 'SpesaHotelPerDestinazioni',
    query: 'getSpesaHotelPerDestinazione',
    category: 'Hotel'
  },
  {
    element: <SpesaPerHotel />,
    name: 'Spesa per hotel',
    id: 'SpesaPerHotel',
    query: 'getSpesaPerHotel',
    category: 'Hotel'
  },
  {
    element: <SpesaPerNoleggi />,
    name: 'Spesa per noleggi',
    id: 'SpesaPerNoleggi',
    query: 'getNoleggiPerDestinazione',
    category: 'Noleggio auto'
  },
  {
    element: <TopFornitoriNoleggi />,
    name: 'Top fornitori noleggi',
    id: 'TopFornitoriNoleggi',
    query: 'getNoleggiPerFornitore',
    category: 'Noleggio auto'
  },
  {
    element: <SavingsPerTipoItinerario />,
    name: 'Saving per tipo itinerario',
    id: 'SavingsPerTipoItinerario',
    query: 'getSavingsPerTipoItinerario',
    category: 'Voli'
  },
  {
    element: <SavingsPerTipoTariffa />,
    name: 'Saving per tipo tariffa',
    id: 'SavingsPerTipoTariffa',
    query: 'getSavingsPerTipoTariffa',
    category: 'Voli'
  },
  {
    element: <SavingsPerMotivazione1 />,
    name: 'Saving per motivazione',
    id: 'SavingsPerMotivazione1',
    query: 'getSavingsPerMotivazione1',
    category: 'Voli'
  },
  {
    element: <TopPassengersBiglietti />,
    name: 'Top passeggeri',
    id: 'TopPassengersBiglietti',
    query: 'getTopPassengersBiglietti',
    category: 'Generale'
  }
]

export const baseUrlPdfPrinter = window?._env_?.ENVIRONMENT === 'production'
  ? 'https://apptourpdf.siapcn.it'
  : window?._env_?.ENVIRONMENT === 'staging'
    ? 'https://apptourpdfstaging.siap.cloud'
    : 'http://localhost:5051'

export const getOrigin = () => {
  try {
    const { origin } = window.location
    return origin
  } catch (e) { }
  return 'http://localhost:4000'
}
