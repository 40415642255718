import React, { useEffect, useState } from 'react'
import { getUrlParams } from '../../Services/Functions'
import { Api } from '../../Charts/Functions'
import { components } from './Pages'
import { FirstPage } from './Pages/FirstPage'

export const ReportTemplate = () => {
  const [report, setReport] = useState()

  useEffect(() => {
    const loadReport = async () => {
      const reportId = getUrlParams('reportId')
      if (!reportId) return
      const { data } = await Api().get(`reports/${reportId}`)
      setReport(data)
    }
    loadReport()
  }, [])

  if (!report) return null

  return (
    <>
      <FirstPage report={report} />
      {report?.reportsList?.map(({ type, tableData }, key) => {
        const element = components.find(({ type: componentType }) => componentType === type)?.element
        if (!element) return null
        return (
          <div key={key}>
            {{ ...element, props: { ...element?.props, dbData: tableData } }}
          </div>
        )
      }
      )}
    </>
  )
}
