import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { formatPrice } from '../../../../Charts/Functions'

export function SpesaNoleggiPerDestinazioni ({ dbData = [] }) {
  const [tableData, setTableData] = useState([])
  const [dataForChart, setDataForChart] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()

  useEffect(() => {
    const first20Year1Elements = []
    const first20Year2Elements = []
    const allYear1Elements = dbData.filter(({ Anno }) => Anno === year1).sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)
    const allYear2Elements = dbData.filter(({ Anno }) => Anno === year2).sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)

    allYear1Elements.forEach((y1El) => {
      const y2El = allYear2Elements.find(({ Citta: c, RagioneSociale: n }) => c === y1El?.Citta && n === y1El?.RagioneSociale)
      if (first20Year1Elements.length < 20) {
        first20Year1Elements.push(y1El)
        first20Year2Elements.push(y2El || { ...y1El, Anno: year2, SpesaComplessiva: '0', NumeroGiorni: '0', PrezzoMedioAlGiorno: '0' })
      }
    })
    setDataForChart([...first20Year1Elements, ...first20Year2Elements])
    const elements = []
    first20Year1Elements.forEach(({ Citta, SpesaComplessiva, NumeroGiorni, PrezzoMedioAlGiorno, RagioneSociale }) => {
      const year2Element = first20Year2Elements.find(({ Citta: Citta2, RagioneSociale: RagioneSociale2 }) => Citta === Citta2 && RagioneSociale === RagioneSociale2)
      elements.push({
        Anno: year1,
        Citta: Citta + ' - ' + RagioneSociale,
        SpesaComplessiva,
        NumeroGiorni,
        PrezzoMedioAlGiorno: parseFloat(PrezzoMedioAlGiorno)?.toFixed(2),
        SpesaComplessiva2: year2Element?.SpesaComplessiva,
        NumeroGiorni2: year2Element?.NumeroGiorni,
        PrezzoMedioAlGiorno2: parseFloat(year2Element?.PrezzoMedioAlGiorno)?.toFixed(2)
      })
    })
    elements.push({
      Anno: year1,
      Citta: 'Altri',
      SpesaComplessiva: allYear1Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroGiorni: allYear1Elements.slice(20).reduce((acc, { NumeroGiorni }) => acc + parseFloat(NumeroGiorni), 0).toFixed(2),
      PrezzoMedioAlGiorno: parseFloat(allYear1Elements.slice(20).reduce((acc, { PrezzoMedioAlGiorno }) => acc + parseFloat(PrezzoMedioAlGiorno), 0) / allYear1Elements.slice(20).length)?.toFixed(2),
      SpesaComplessiva2: allYear2Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroGiorni2: allYear2Elements.slice(20).reduce((acc, { NumeroGiorni }) => acc + parseFloat(NumeroGiorni), 0).toFixed(2),
      PrezzoMedioAlGiorno2: parseFloat(allYear2Elements.slice(20).reduce((acc, { PrezzoMedioAlGiorno }) => acc + parseFloat(PrezzoMedioAlGiorno), 0) / allYear2Elements.slice(20).length)?.toFixed(2)
    })
    elements.push({
      Anno: year1,
      Citta: 'Totale',
      SpesaComplessiva: allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroGiorni: allYear1Elements.reduce((acc, { NumeroGiorni }) => acc + parseFloat(NumeroGiorni), 0).toFixed(2),
      PrezzoMedioAlGiorno: parseFloat(allYear1Elements.reduce((acc, { PrezzoMedioAlGiorno }) => acc + parseFloat(PrezzoMedioAlGiorno), 0) / allYear1Elements.length)?.toFixed(2),
      SpesaComplessiva2: allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroGiorni2: allYear2Elements.reduce((acc, { NumeroGiorni }) => acc + parseFloat(NumeroGiorni), 0).toFixed(2),
      PrezzoMedioAlGiorno2: parseFloat(allYear2Elements.reduce((acc, { PrezzoMedioAlGiorno }) => acc + parseFloat(PrezzoMedioAlGiorno), 0) / allYear2Elements.length)?.toFixed(2)
    })
    setTableData(elements)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`Car - Analisi Spesa per Città ${year1} vs ${year2}`}
            subTitle='La lista dei noleggi raggruppati per città e fornitore con il costo totale, il numero di giorni e il prezzo medio al giorno per anno.'
          />
          <Table tableData={tableData} years={[year1, year2]} />
        </Flex>
      </div>
      <Chart data={dataForChart} />
    </>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Destinazione - Fornitore</th>
          <th>Spesa Totale {year1}</th>
          <th>Somma Numero Giorni {year1}</th>
          <th>Prezzo Medio Al giorno {year1}</th>
          <th>Spesa Totale {year2}</th>
          <th>Somma Numero Giorni {year2}</th>
          <th>Prezzo Medio Al giorno {year2}</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Citta, RagioneSociale, SpesaComplessiva, NumeroGiorni, PrezzoMedioAlGiorno, SpesaComplessiva2, NumeroGiorni2, PrezzoMedioAlGiorno2 }, key) =>
          <tr key={key} style={{ fontWeight: Citta === 'Totale' ? 'bold' : 'normal' }}>
            <td>{Citta}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{NumeroGiorni}</td>
            <td>{formatPrice(PrezzoMedioAlGiorno)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva2)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{NumeroGiorni2}</td>
            <td>{formatPrice(PrezzoMedioAlGiorno2)}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
