import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Spinner, Text } from '../../Components'
import { Api } from '../../Charts/Functions'
import { reportPages } from './ReportEditor/Functions'

export function SelectReport ({ filter, onSelect, onHide, selectedReports }) {
  const [page, setPage] = useState('selectReport')

  const reportPage = reportPages.find(({ id }) => id === page) || {}
  const { query, element, id, name, category } = reportPage || {}

  return (
    <Flex fw style={{ minHeight: '100%' }}>
      <Text value='Aggiungi pagina' title bold size={30} style={{ marginBottom: 10 }} />
      <Text value='Scegli la pagina che vuoi aggiungere al report e modifica / conferma i dati' size={18} style={{ margin: 10 }} />
      {page === 'selectReport'
        ? (<ReportCategoriesPages onHide={onHide} openPage={(page) => setPage(page)} selectedReports={selectedReports} />)
        : (
          <CommonSelect {...{ query, filter }} onConfirm={(tableData) => onSelect({ type: id, category, name, tableData })} goBack={() => setPage('selectReport')}>
            {element}
          </CommonSelect>)}
    </Flex>
  )
}

const CommonSelect = ({ filter, query, children, onConfirm, goBack }) => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const loadReportData = async () => {
    const year = filter?.year || new Date().getFullYear()
    const months = filter?.months || [0, 11]
    const clients = filter?.clients || []
    // const tipoBiglietteria = filter?.tipoBiglietteria || ''
    const filterClients = filter?.filterClients || false
    setLoading(true)
    const { data: { data } } = await Api()(`biDB/${query}`, {
      params: {
        year,
        months: Array.from({ length: months[1] - months[0] + 1 }, (_, i) => i + months[0]),
        clients: filterClients ? clients.map(({ codCliente12 }) => codCliente12) : []
        // tipoBiglietteria
      }
    })
    setTableData(data || [])
    setLoading(false)
  }

  useEffect(() => { loadReportData() }, [])

  if (loading) return <Spinner />
  return (
    <Flex fw>
      {{ ...children, props: { ...children.props, tableData: [tableData, setTableData] } }}
      <Flex row>
        <Button label='Torna indietro' onClick={goBack} icon='back' style={{ marginRight: 10 }} />
        <Button label='Conferma Dati' onClick={() => onConfirm(tableData)} icon='check' />
      </Flex>
    </Flex>
  )
}

const ReportCategoriesPages = ({ openPage, onHide, selectedReports }) => {
  const categories = reportPages.map(({ category }) => category).filter((value, index) => reportPages.map(({ category }) => category).indexOf(value) === index)

  return (
    <Flex fw>
      <Flex fw as row wrap>
        {categories.map((category) => (
          <Card key={category} style={{ margin: 10, width: 600 }}>
            <Text value={category} bold size={20} style={{ margin: 10 }} />
            {reportPages.filter(({ category: cat }) => cat === category).map(({ id, name }) => {
              const isAlreadySelected = selectedReports.find(({ type, category: sCategory }) => type === id && sCategory === category)
              return (
                <Flex fw js row style={{ margin: 10, cursor: isAlreadySelected ? 'not-allowed' : 'pointer' }} key={id}>
                  <Text value={name} size={20} color={isAlreadySelected ? 'grey' : 'black'} />
                  <Button
                    tooltip='Scegli' round disabled={isAlreadySelected}
                    style={{ marginLeft: 10 }}
                    icon='edit' onClick={() => openPage(id)}
                  />
                </Flex>
              )
            })}
          </Card>))}
      </Flex>
      <Button label='Torna indietro' onClick={onHide} icon='back' style={{ marginTop: 10 }} />
    </Flex>
  )
}
