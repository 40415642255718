import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Flex } from '../../../../Components'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const lineOptions = () => ({
  indexAxis: 'x',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    legend: {
      position: 'right'
    },
    title: {
      display: false,
      text: 'Spesa complessiva mensile'
    }
  }
})

export function Chart ({ data = [] }) {
  const [lineData, setLineData] = useState()

  const [year1 = '', year2 = ''] = data.map(({ Anno }) => Anno).filter((value, index) => data.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  useEffect(() => {
    if (!data?.length) return
    let labels = data.map(({ Mese }) => Mese?.split('_')[1])
    labels = labels.filter((value, index) => labels.indexOf(value) === index)
    setLineData({
      labels,
      datasets: [
        {
          label: year1,
          data: labels.map((l) => data?.find(({ Mese, Anno }) => Mese?.split('_')[1] === l && Anno === year1)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: year2,
          data: labels.map((l) => data?.find(({ Mese, Anno }) => Mese?.split('_')[1] === l && Anno === year2)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    })
  }, [data])

  if (!lineData) return null
  return (
    <Flex style={{ width: 1200, height: 550 }}>
      <Line options={lineOptions()} data={lineData} />
    </Flex>
  )
}
