import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Flex } from '../../../../Components'
import { ChartTitle } from '../Components'
import { tipoBiglietteriaOptions } from '../../../../Services/Functions'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const barOptions = {
  indexAxis: 'x',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    title: {
      display: false
    }
  }
}

export function Chart ({ data = [], tipoBiglietteria }) {
  const [barData, setBarData] = useState()

  const [year1 = '', year2 = ''] = data.map(({ Anno }) => Anno).filter((value, index) => data.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  useEffect(() => {
    if (!data?.length) return
    let labels = data.map(({ Vettore }) => Vettore)
    labels = labels.filter((value, index) => labels.indexOf(value) === index)
    setBarData({
      labels,
      datasets: [
        {
          label: year1,
          data: labels.map((l) => data?.find(({ Vettore, Anno }) => Vettore === l && Anno === year1)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: year2,
          data: labels.map((l) => data?.find(({ Vettore, Anno }) => Vettore === l && Anno === year2)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    })
  }, [data])

  if (!barData) return null

  if (tipoBiglietteria === 'F') {
    return (
      <Bar options={barOptions} data={barData} />
    )
  }

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh>
        <Flex style={{ width: 1500, height: 1200 }}>
          <ChartTitle title={`${tipoBiglietteriaOptions.find(({ value }) => value === tipoBiglietteria)?.label} - Analisi Spesa per Vettore ${year1} vs ${year2}`} />
          <Bar options={barOptions} data={barData} />
        </Flex>
      </Flex>
    </div>
  )
}
