import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Flex, Spinner } from '../../../Components'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const barOptions = () => ({
  indexAxis: 'x',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    legend: { position: 'right' },
    title: {
      display: true,
      text: 'Saving per Tipo Tariffa'
    }
  }
})

export function ChartSavings ({ data = [] }) {
  const [barData, setBarData] = useState()

  useEffect(() => {
    if (!data?.length) return
    let labels = data.map(({ TipoTariffa }) => TipoTariffa).slice(0, 40)
    labels = labels.filter((value, index) => labels.indexOf(value) === index)

    setBarData({
      labels,
      datasets: [
        {
          label: 'Saving',
          data: labels.map((l) => data?.find(({ TipoTariffa, Anno }) => TipoTariffa === l)?.Saving || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ]
    })
  }, [data])

  if (!barData) return <Spinner />
  return (
    <Flex fw>
      <Bar options={barOptions()} data={barData} />
    </Flex>
  )
}
