import { useState } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ProtectedRoute, Login, ErrorHandler } from './Pages'
import AuthProvider, { useAuth } from './Provider/AuthProvider'
import { FC } from './Services'
import { Flex, Spinner, Background } from './Components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReportTemplate } from './Pages/ReportTemplate'
import { Users } from './Pages/Users'
import { User } from './Pages/Users/User'
import { ReportList } from './Pages/Report/ReportList'
import { ReportEditor } from './Pages/Report/ReportEditor'

export const queryClient = new QueryClient()

function AppSetup () {
  const { user: storedUser, onLoginJWT } = useAuth()
  const [loading, setLoading] = useState(true)

  useMount(async () => {
    if (FC.authenticated && !storedUser?.accessToken) {
      setLoading(false)
      return true
    }
    storedUser?.accessToken && await onLoginJWT(storedUser?.accessToken)
    setLoading(false)
  })

  return (
    <Background>
      <ReactQueryDevtools initialIsOpen={false} />
      {loading ? <Flex fw fh><Spinner /></Flex> : <Outlet />}
    </Background>
  )
}

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorHandler />,
    children: [
      {
        element: <AppSetup />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: '*', index: true, element: <Navigate to='/report/list' /> },
              { path: '/users/list', element: <Users /> },
              { path: '/users/:userId', element: <User /> },
              { path: '/report/:reportId', element: <ReportEditor /> },
              { path: '/report/list', element: <ReportList /> }
            ]
          },
          { path: '/login', element: <Login /> }
        ]
      },
      { path: '/template', element: <ReportTemplate /> }
    ]
  }
])

function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
export default App
