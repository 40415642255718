import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { formatPrice } from '../../../../Charts/Functions'

export function TopPassengersBiglietti ({ dbData = [] }) {
  const [tableData, setTableData] = useState([])
  const [dataForChart, setDataForChart] = useState([])

  const [year1 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()
  useEffect(() => {
    const first20Year1Elements = []
    const arr1 = dbData.filter(({ Anno, NomePasseggero }) => Anno === year1 && NomePasseggero !== 'Altri').sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)

    const allYear1Elements = []
    arr1.forEach(({ NomePasseggero: c }) => {
      if (!allYear1Elements.find(({ NomePasseggero: c2 }) => c2 === c)) {
        allYear1Elements.push(...arr1.filter(({ NomePasseggero: c2, Destinazione }) => c2 === c))
      }
    })

    allYear1Elements.forEach((y1El) => {
      if (!first20Year1Elements.includes(y1El?.NomePasseggero) && first20Year1Elements.length < 25) {
        first20Year1Elements.push(y1El)
      }
    })
    setDataForChart([...first20Year1Elements])
    const elements = []
    first20Year1Elements.forEach(({ NomePasseggero, SpesaComplessiva, Quantita, Destinazione }) => {
      elements.push({
        Anno: year1,
        NomePasseggero,
        SpesaComplessiva,
        Quantita,
        Destinazione
      })
    })

    elements.push({
      Anno: year1,
      NomePasseggero: 'Altri',
      SpesaComplessiva: allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2) - first20Year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      Quantita: allYear1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(0) - first20Year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(0),
      Destinazione: ''
    })

    elements.push({
      Anno: year1,
      NomePasseggero: 'Totale',
      SpesaComplessiva: allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      Quantita: allYear1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(0),
      Destinazione: ''
    })

    setTableData(elements)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`Report Spesa per passeggero ${year1}`}
            subTitle='La lista dei passeggeri con la maggiore spesa complessiva'
          />
          <Table tableData={tableData} years={[year1]} />
        </Flex>
      </div>
      <Chart data={dataForChart} />
    </>
  )
}

const Table = ({ tableData, years: [year1] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Passeggero</th>
          <th>Destinazione</th>
          <th>Spesa Totale</th>
          <th>Quantità</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Anno, NomePasseggero, SpesaComplessiva, Quantita, Destinazione }, key) =>
          <tr key={key} style={{ fontWeight: NomePasseggero === 'Totale' ? 'bold' : 'normal' }}>
            <td>{NomePasseggero}</td>
            <td>{Destinazione}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
