import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { formatPrice } from '../../../../Charts/Functions'

export function SpesaHotelPerHotel ({ dbData = [] }) {
  const [tableData, setTableData] = useState([])
  const [dataForChart, setDataForChart] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()

  useEffect(() => {
    const first20Year1Elements = []
    const first20Year2Elements = []
    const allYear1Elements = dbData.filter(({ Anno, Localita }) => Anno === year1 && Localita !== 'Altri').sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)
    const allYear2Elements = dbData.filter(({ Anno, Localita }) => Anno === year2 && Localita !== 'Altri').sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)

    allYear1Elements.forEach((y1El) => {
      const y2El = allYear2Elements.find(({ Localita: c, RagioneSociale: n }) => c === y1El?.Localita && n === y1El?.RagioneSociale)
      if (first20Year1Elements.length < 20) {
        first20Year1Elements.push(y1El)
        first20Year2Elements.push(y2El || { ...y1El, Anno: year2, SpesaComplessiva: '0', NumeroNotti: '0', PrezzoMedioANotte: '0' })
      }
    })
    setDataForChart([...first20Year1Elements, ...first20Year2Elements])
    const elements = []
    first20Year1Elements.forEach(({ Localita, SpesaComplessiva, NumeroNotti, PrezzoMedioANotte, RagioneSociale }) => {
      const year2Element = first20Year2Elements.find(({ Localita: Localita2, RagioneSociale: RagioneSociale2 }) => Localita === Localita2 && RagioneSociale === RagioneSociale2)
      elements.push({
        Anno: year1,
        Hotel: `${RagioneSociale} (${Localita})`,
        RagioneSociale,
        SpesaComplessiva,
        NumeroNotti,
        PrezzoMedioANotte: parseFloat(PrezzoMedioANotte)?.toFixed(2),
        SpesaComplessiva2: year2Element?.SpesaComplessiva,
        NumeroNotti2: year2Element?.NumeroNotti,
        PrezzoMedioANotte2: parseFloat(year2Element?.PrezzoMedioANotte)?.toFixed(2)
      })
    })
    const year1Other = dbData.filter(({ Anno, Localita }) => Anno === year1 && Localita === 'Altri')
    const year2Other = dbData.filter(({ Anno, Localita }) => Anno === year2 && Localita === 'Altri')
    const year1ElementForOther = [...allYear1Elements.slice(20), ...year1Other]
    const year2ElementForOther = [...allYear2Elements.slice(20), ...year2Other]
    elements.push({
      Anno: year1,
      Hotel: 'Altri',
      SpesaComplessiva: year1ElementForOther.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroNotti: year1ElementForOther.reduce((acc, { NumeroNotti }) => acc + parseFloat(NumeroNotti), 0).toFixed(0),
      PrezzoMedioANotte: parseFloat(year1ElementForOther.reduce((acc, { PrezzoMedioANotte }) => acc + parseFloat(PrezzoMedioANotte), 0) / year1ElementForOther.length)?.toFixed(2),
      SpesaComplessiva2: year2ElementForOther.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroNotti2: year2ElementForOther.reduce((acc, { NumeroNotti }) => acc + parseFloat(NumeroNotti), 0).toFixed(0),
      PrezzoMedioANotte2: parseFloat(year2ElementForOther.reduce((acc, { PrezzoMedioANotte }) => acc + parseFloat(PrezzoMedioANotte), 0) / year2ElementForOther.length)?.toFixed(2)
    })
    const year1ElementForTotal = [...allYear1Elements, ...year1Other]
    const year2ElementForTotal = [...allYear2Elements, ...year2Other]
    elements.push({
      Anno: year1,
      Hotel: 'Totale',
      SpesaComplessiva: year1ElementForTotal.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroNotti: year1ElementForTotal.reduce((acc, { NumeroNotti }) => acc + parseFloat(NumeroNotti), 0).toFixed(0),
      PrezzoMedioANotte: parseFloat(year1ElementForTotal.reduce((acc, { PrezzoMedioANotte }) => acc + parseFloat(PrezzoMedioANotte), 0) / year1ElementForTotal.length)?.toFixed(2),
      SpesaComplessiva2: year2ElementForTotal.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      NumeroNotti2: year2ElementForTotal.reduce((acc, { NumeroNotti }) => acc + parseFloat(NumeroNotti), 0).toFixed(0),
      PrezzoMedioANotte2: parseFloat(year2ElementForTotal.reduce((acc, { PrezzoMedioANotte }) => acc + parseFloat(PrezzoMedioANotte), 0) / year2ElementForTotal.length)?.toFixed(2)
    })
    setTableData(elements)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`Hotel - Analisi Spesa ${year1} vs ${year2}`}
            subTitle='La lista degli hotel più frequenti con il costo totale, la quantità e la spesa media per anno.'
          />
          <Table tableData={tableData} years={[year1, year2]} />
        </Flex>
      </div>
      <Chart data={dataForChart} />
    </>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Destinazione</th>
          <th>Spesa Totale {year1}</th>
          <th>Somma Numero Notti {year1}</th>
          <th>Prezzo Medio A Notte {year1}</th>
          <th>Spesa Totale {year2}</th>
          <th>Somma Numero Notti {year2}</th>
          <th>Prezzo Medio A Notte {year2}</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Hotel, Nazione, SpesaComplessiva, NumeroNotti, PrezzoMedioANotte, SpesaComplessiva2, NumeroNotti2, PrezzoMedioANotte2 }, key) =>
          <tr key={key} style={{ fontWeight: Hotel === 'Totale' ? 'bold' : 'normal' }}>
            <td>{Hotel}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{NumeroNotti}</td>
            <td>{formatPrice(PrezzoMedioANotte)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva2)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{NumeroNotti2}</td>
            <td>{formatPrice(PrezzoMedioANotte2)}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
