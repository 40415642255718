import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { tipoBiglietteriaOptions } from '../../../../Services/Functions'
import { formatPrice } from '../../../../Charts/Functions'

export function TopDestinazioni ({ dbData = [], tipoBiglietteria }) {
  const [tableData, setTableData] = useState([])
  const [dataForChart, setDataForChart] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()
  useEffect(() => {
    const first20Year1Elements = []
    const first20Year2Elements = []
    const allYear1Elements = dbData.filter(({ Anno }) => Anno === year1).sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)
    const allYear2Elements = dbData.filter(({ Anno }) => Anno === year2).sort((a, b) => b.SpesaComplessiva - a.SpesaComplessiva)

    allYear1Elements.forEach((y1El) => {
      const y2El = allYear2Elements.find(({ Citta: c }) => c === y1El?.Citta)
      if (!first20Year1Elements.includes(y1El?.Citta) && first20Year1Elements.length < 20) {
        first20Year1Elements.push(y1El)
        first20Year2Elements.push(y2El || { ...y1El, Anno: year2, SpesaComplessiva: '0', Quantita: '0', SpesaMedia: '0' })
      }
    })
    setDataForChart([...first20Year1Elements, ...first20Year2Elements])
    const elements = []
    first20Year1Elements.forEach(({ Citta, SpesaComplessiva, Quantita }) => {
      const year2Element = first20Year2Elements.find(({ Citta: Citta2 }) => Citta === Citta2)
      elements.push({
        Anno: year1,
        Citta,
        SpesaComplessiva,
        SpesaComplessiva2: year2Element?.SpesaComplessiva,
        DeltaSpesa: (SpesaComplessiva - year2Element?.SpesaComplessiva).toFixed(2),
        Quantita,
        Quantita2: year2Element?.Quantita,
        SpesaMedia: (SpesaComplessiva / Quantita).toFixed(2),
        SpesaMedia2: (year2Element?.SpesaComplessiva / year2Element?.Quantita).toFixed(2),
        DeltaSpesaMediaPercentage: (((SpesaComplessiva / Quantita) - (year2Element?.SpesaComplessiva / year2Element?.Quantita)) / (year2Element?.SpesaComplessiva / year2Element?.Quantita) * 100).toFixed(2)
      })
    })
    elements.push({
      Anno: year1,
      Citta: 'Altri',
      SpesaComplessiva: allYear1Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      SpesaComplessiva2: allYear2Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      DeltaSpesa: (allYear1Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - allYear2Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)).toFixed(2),
      Quantita: allYear1Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(2),
      Quantita2: allYear2Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(2),
      SpesaMedia: (allYear1Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear1Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(2),
      SpesaMedia2: (allYear2Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear2Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(2),
      DeltaSpesaMediaPercentage: (((allYear1Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear1Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) - (allYear2Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear2Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0))) / (allYear2Elements.slice(20).reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear2Elements.slice(20).reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) * 100).toFixed(2)
    })
    elements.push({
      Anno: year1,
      Citta: 'Totale',
      SpesaComplessiva: allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      SpesaComplessiva2: allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      DeltaSpesa: (allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)).toFixed(2),
      Quantita: allYear1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(2),
      Quantita2: allYear2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(2),
      SpesaMedia: (allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(2),
      SpesaMedia2: (allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(2),
      DeltaSpesaMediaPercentage: (((allYear1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) - (allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0))) / (allYear2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) / allYear2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) * 100).toFixed(2)
    })

    setTableData(elements)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`${tipoBiglietteriaOptions.find(({ value }) => value === tipoBiglietteria)?.label} - Analisi Top Destinations ${year1} vs ${year2}`}
            subTitle='La lista delle destinazioni più frequenti con il costo totale, la quantità e la spesa media per anno.'
          />
          <Table tableData={tableData} years={[year1, year2]} />
        </Flex>
      </div>
      <Chart data={dataForChart} tipoBiglietteria={tipoBiglietteria} />
    </>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Destinazione</th>
          <th>Spesa Totale {year1}</th>
          <th>Spesa Totale {year2}</th>
          <th>Delta Spesa</th>
          <th>Quantità {year1}</th>
          <th>Quantità {year2}</th>
          <th>Spesa Media {year1}</th>
          <th>Spesa Media {year2}</th>
          <th>Delta Spesa Media %</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Anno, Citta, SpesaComplessiva, SpesaComplessiva2, DeltaSpesa, Quantita, Quantita2, DeltaSpesaMediaPercentage, SpesaMedia, SpesaMedia2 }, key) =>
          <tr key={key} style={{ fontWeight: Citta === 'Totale' ? 'bold' : 'normal' }}>
            <td>{Citta}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva2)}</td>
            <td>{formatPrice(DeltaSpesa)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita2}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaMedia)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaMedia2)}</td>
            <td style={{ backgroundColor: DeltaSpesaMediaPercentage > 0 ? '#80dc7282' : '#dc728080', color: DeltaSpesaMediaPercentage > 0 ? '#0f6e0f' : '#6e0f0f' }}>{DeltaSpesaMediaPercentage} %</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
