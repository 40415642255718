import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { Chart } from './Chart'
import { formatPrice } from '../../../../Charts/Functions'

export function SpesaPerArea ({ dbData = [] }) {
  const [tableData, setTableData] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()
  useEffect(() => {
    const elements = []
    const year1Elements = dbData.filter(({ Anno }) => Anno === year1)
    const year2Elements = dbData.filter(({ Anno }) => Anno === year2)

    year1Elements.forEach(({ Area, SpesaComplessiva, Quantita }) => {
      const year2Element = year2Elements.find(({ Area: Area2 }) => Area === Area2)
      elements.push({
        Anno: year1,
        Area,
        SpesaComplessiva,
        Quantita,
        SpesaComplessiva2: year2Element?.SpesaComplessiva,
        Quantita2: year2Element?.Quantita,
        DeltaSpesa: (SpesaComplessiva - year2Element?.SpesaComplessiva).toFixed(2),
        DeltaSpesaPercentuale: year2Element?.SpesaComplessiva ? ((SpesaComplessiva - year2Element?.SpesaComplessiva) / year2Element?.SpesaComplessiva * 100).toFixed(2) : 0,
        DeltaQuantita: (Quantita - year2Element?.Quantita).toFixed(0),
        DeltaQuantitaPercentuale: year2Element?.Quantita ? ((Quantita - year2Element?.Quantita) / year2Element?.Quantita * 100).toFixed(2) : 0
      })
    })
    elements.push({
      Anno: year1,
      Area: 'Totale',
      SpesaComplessiva: year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      Quantita: year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(2),
      SpesaComplessiva2: year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0).toFixed(2),
      Quantita2: year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0).toFixed(2),
      DeltaSpesa: (year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)).toFixed(2),
      DeltaSpesaPercentuale: year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) ? ((year1Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) - year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0)) / year2Elements.reduce((acc, { SpesaComplessiva }) => acc + parseFloat(SpesaComplessiva), 0) * 100).toFixed(2) : 0,
      DeltaQuantita: (year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0) - year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)).toFixed(0),
      DeltaQuantitaPercentuale: year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0) ? ((year1Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0) - year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0)) / year2Elements.reduce((acc, { Quantita }) => acc + parseFloat(Quantita), 0) * 100).toFixed(2) : 0
    })

    setTableData(elements)
  }, [dbData])

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fh fw>
        <ChartTitle
          title={`Report Spesa per Tipologia di servizio ${year1} vs ${year2}`}
          subTitle='La lista delle aree con il costo totale e la quantità per anno.'
        />
        <Table tableData={tableData} years={[year1, year2]} />
        <Chart data={dbData} />
      </Flex>
    </div>
  )
}

const Table = ({ tableData, years: [year1, year2] }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Area</th>
          <th>Spesa Totale {year1}</th>
          <th>Spesa Totale {year2}</th>
          <th>Delta Spesa</th>
          <th>Delta Spesa %</th>
          <th>Quantità {year1}</th>
          <th>Quantità {year2}</th>
          <th>Delta Quantità</th>
          <th>Delta Quantità %</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ Anno, Area, SpesaComplessiva, Quantita, SpesaComplessiva2, Quantita2, DeltaSpesa, DeltaQuantita, DeltaSpesaPercentuale, DeltaQuantitaPercentuale }, key) =>
          <tr key={key} style={{ fontWeight: Area === 'Totale' ? 'bold' : 'normal' }}>
            <td>{Area}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SpesaComplessiva2)}</td>
            <td>{formatPrice(DeltaSpesa)}</td>
            <td style={{ backgroundColor: DeltaSpesaPercentuale > 0 ? '#80dc7282' : '#dc728080', color: DeltaSpesaPercentuale > 0 ? '#0f6e0f' : '#6e0f0f' }}>{DeltaSpesaPercentuale} %</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Quantita2}</td>
            <td>{DeltaQuantita}</td>
            <td style={{ backgroundColor: DeltaSpesaPercentuale > 0 ? '#80dc7282' : '#dc728080', color: DeltaSpesaPercentuale > 0 ? '#0f6e0f' : '#6e0f0f' }}>{DeltaQuantitaPercentuale} %</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
