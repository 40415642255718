import { useState } from 'react'
import { Button, Flex, Input, Spinner, Text } from '..'
import { gridTemplate, searchMap } from './Functions'
import { ClientBox } from './ClientBox'

const fieldMap = [{ id: 'mastro', label: 'Mastro', maxLength: 2 }, { id: 'gruppo', label: 'Gruppo', maxLength: 2 }, { id: 'conto', label: 'Conto', maxLength: 2 }, { id: 'sottoConto', label: 'Sotto Conto', maxLength: 6 }]

export const ClientCodeSearch = ({ isSearching, searchClient, onCleanUp, changeSearchType }) => {
  const [code, setCode] = useState({ mastro: '', gruppo: '', conto: '', sottoConto: '' })

  const handleSearch = () => {
    if (Object.values(code).some(v => v === '')) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire almeno un parametro di ricerca' })
    const { mastro, gruppo, conto, sottoConto } = code
    const stringCode = `${mastro.padStart(2, ' ')}${gruppo.padStart(2, ' ')}${conto.padStart(2, ' ')}${sottoConto.padStart(6, ' ')}`
    searchClient(stringCode)
  }

  const cleanSearch = () => {
    setCode({ mastro: '', gruppo: '', conto: '', sottoConto: '' })
    onCleanUp()
  }

  const onClickBack = () => changeSearchType(null)()

  return (
    <Flex fw js as style={{ padding: 20 }}>
      <Flex style={{ marginBottom: 10 }}>
        <Text value='Ricerca per codice cliente' title bold size={20} />
      </Flex>
      <Flex row fw>
        {Object.entries(code).map(([key, value], ind) => (
          <Input
            key={key} style={{
              margin: 9,
              ...(ind ? { marginLeft: 0 } : {}),
              ...(ind === Object.entries(code).length - 1 ? { marginRight: 0 } : {})
            }} value={value} placeholder={fieldMap?.find(({ id }) => id === key)?.label} id={key} onChange={(e) => e?.[key]?.length <= fieldMap?.find(({ id }) => id === key)?.maxLength && setCode({ ...code, [key]: e?.[key] })} keyfilter='int' onEnter={handleSearch}
          />
        ))}
      </Flex>
      <Flex row fw je>
        <Button label='Ricerca' icon='lens' onClick={handleSearch} disabled={isSearching} />
        <Button style={{ marginLeft: 10, marginRight: 10 }} label='Pulisci campi' icon='bin' onClick={cleanSearch} />
        <Button label='Cambia tipo ricerca' icon='back' onClick={onClickBack} />
      </Flex>
    </Flex>
  )
}

export const ClientFieldSearch = ({ isSearching, searchClient, onCleanUp, changeSearchType }) => {
  const [querySearch, setQuerySearch] = useState({ ragioneSociale: '', partitaIva: '', codNazione: '', gruppoCliente: '', codRegione: '', codZona: '', localita: '', email: '', numeroTel: '', codiceFiscale: '' })

  const handleSearch = () => {
    if (Object.values(querySearch).every(v => v === '')) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire almeno un parametro di ricerca' })
    searchClient(querySearch)
  }

  const onClean = () => {
    setQuerySearch({ codNazione: '', codRegione: '', codZona: '', gruppoCliente: '', email: '', localita: '', numeroTel: '', ragioneSociale: '', codiceFiscale: '', partitaIva: '' })
    onCleanUp()
  }

  const onClickBack = () => {
    changeSearchType(null)()
  }

  return (
    <Flex fw js as style={{ padding: 20 }}>
      <Flex style={{ marginBottom: 10 }}>
        <Text value='Ricerca per dettagli anagrafici cliente' title bold size={20} />
      </Flex>
      <div style={{ gridGap: '9px 9px', display: 'grid', gridTemplateAreas: gridTemplate, width: '100%', marginBottom: 10 }}>
        {Object.entries(querySearch).map(([id, value]) => (
          <Input
            key={id} value={value} id={id} placeholder={searchMap?.[id]} onEnter={handleSearch}
            style={{ gridArea: id }} onChange={e => setQuerySearch({ ...querySearch, [id]: e?.[id] })}
          />
        ))}
      </div>
      <Flex row fw je>
        <Button label='Ricerca' icon='lens' onClick={handleSearch} disabled={isSearching} />
        <Button label='Pulisci campi' icon='bin' onClick={onClean} style={{ marginLeft: 10, marginRight: 10 }} />
        <Button label='Cambia tipo ricerca' icon='back' onClick={onClickBack} />
      </Flex>
    </Flex>
  )
}

export const SearchedClients = ({ isSearching, clients, selectedClients: [selectedClients, setSelectedClients], showClientsResults = false }) => {
  if (isSearching) return <Flex fw><Spinner /></Flex>
  const renderClients = () => {
    if (clients?.length === 0) { return <Text value='Nessun risultato trovato...' size={20} /> }
    return clients?.map((client, i) => {
      const alreadyChecked = selectedClients?.some(({ codCliente12 }) => codCliente12 === client?.codCliente12)
      const selectFn = () => alreadyChecked
        ? setSelectedClients(selectedClients.filter(({ codCliente12 }) => codCliente12 !== client?.codCliente12))
        : setSelectedClients([...selectedClients, client])
      return (
        <ClientBox key={i} client={client} onSelect={selectFn} isSelected={alreadyChecked} />
      )
    }
    )
  }

  if (!showClientsResults) return null

  return (
    <Flex fw row wrap style={styles.clientsContainer}>
      {renderClients()}
    </Flex>
  )
}

const styles = {
  clientsContainer: { border: '1px solid black', borderRadius: 20, marginTop: 20, padding: 10, overflow: 'auto', maxHeight: 320 }
}
