import React from 'react'
import { Flex, Text } from '../../../Components'

export const ChartTitle = ({ title = '', subTitle = '', style }) => {
  return (
    <Flex style={{ padding: 0, ...style }} fw>
      <Text value={title} color='#313fd6' bold size={30} />
      <Text size={20} style={{ marginTop: 10, marginBottom: 30 }} value={subTitle} />
    </Flex>
  )
}
