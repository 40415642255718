import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { FC } from '.'
import { queryClient } from '../App'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const queries = {
  users: {
    config: queryConfig.rare,
    queryFn: () => FC.service('users').find({})
  },
  user: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return null
      const res = await FC.service('users').get(id)
      return res
    }
  },
  reports: {
    config: queryConfig.rare,
    queryFn: () => FC.service('reports').find({})
  },
  report: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') {
        return {
          _id: 'new',
          name: '',
          reportsList: [],
          reportFilter: { year: new Date().getFullYear(), months: [0, 11], clients: [], filterClients: false /* tipoBiglietteria: '' */ }
        }
      }
      const [res] = await FC.service('reports').find({ query: { _id: id } })
      return res
    }
  }
}

export const useCQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return useQuery({ queryKey, queryFn, ...config })
}

export const prefetchQuery = (queryKey, extraQueries = []) => {
  const { queryFn, config } = { ...queries, ...extraQueries }[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryClient.prefetchQuery(queryKey, queryFn, config)
}

export const prefetchQueries = (extraQueries) =>
  Object.entries({ ...queries, ...extraQueries }).forEach(([queryKey, { config, queryFn, defaultKeys = [], noInitalPrefetch }]) => {
    const key = [queryKey, ...defaultKeys]
    !noInitalPrefetch && queryClient.prefetchQuery(key, queryFn, config)
  })

export const invalidateQuery = (queryKeys) =>
  (Array.isArray(queryKeys) ? queryKeys : [queryKeys])
    .forEach(queryKey => queryClient.invalidateQueries({ queryKey: [queryKey] }))

// FETCH QUERY

export const fetchQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return queryClient.fetchQuery({ queryKey, queryFn, ...config })
}

// INFINITE QUERIES
const infiniteQueries = {
  documents: {
    firstPageLength: 100,
    pageLength: 100,
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, query = {}] = [] }) =>
      FC.client.service('documents').find({
        query:
        {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
          $limit: pageParam === 1 ? firstPageLength : pageLength,
          $sort: { createdAt: -1 },
          ...query
        }
      }),
    noInitalPrefetch: true
  }
}

export const useCInfiniteQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[queryKey[0]] || queryNotFound

  const { data = {}, isSuccess, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    queryKey,
    queryFn(firstPageLength, pageLength),
    {
      // devo fetchare nuova pagina se sono della lunghezza massima stabilita (firstPageLength o pageLength)
      getNextPageParam: (lastPage, pages) =>
        (pages?.length === 1)
          ? ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === firstPageLength) ? pages?.length + 1 : null
          : ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === pageLength) ? pages?.length + 1 : null,
      ...config
    })

  // controllo se la pagina è un array o un oggetto con data e ritorno un array di pagine.
  // perchè se è definita la paginazione sul server ritorna un oggetto con data e se non è definita ritorna un array
  const pages = data?.pages?.map(page => Array.isArray(page) ? page : page.data).flat()

  useEffect(() => { !isFetching && !isFetchingNextPage && hasNextPage && fetchNextPage() }, [isFetchingNextPage, isFetching])

  return { data: pages, isSuccess, isFetchingNextPage }
}
