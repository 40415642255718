import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Spinner } from '../../../../Components/Common/Spinner'
import { Flex } from '../../../../Components'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const barOptions = (year1, year2) => ({
  indexAxis: 'y',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    legend: { position: 'right' },
    title: { display: false }
  }
})

export function Chart ({ data, tipoBiglietteria }) {
  const [chartData, setChartData] = useState()

  const [year1 = '', year2 = ''] = data.map(({ Anno }) => Anno).filter((value, index) => data.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  useEffect(() => {
    let labels = data.filter(({ TipoBiglietteria }) => TipoBiglietteria === tipoBiglietteria).map(({ NazConInt, Classe }) => NazConInt + ' - ' + Classe)
    labels = labels.filter((value, index) => labels.indexOf(value) === index).sort()
    setChartData({
      labels,
      datasets: [
        {
          label: year1,
          data: labels.map((l) => data?.find(({ NazConInt, Classe, Anno }) => NazConInt + ' - ' + Classe === l && Anno === year1)?.PrezzoMedio || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: year2,
          data: labels.map((l) => data?.find(({ NazConInt, Classe, Anno }) => NazConInt + ' - ' + Classe === l && Anno === year2)?.PrezzoMedio || '0'),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    })
  }, [data])

  if (!chartData) return <Spinner />
  return (
    <Flex style={{ width: 1500, height: 400, marginTop: 10 }}>
      <Bar options={barOptions(year1, year2)} data={chartData} />
    </Flex>
  )
}
