import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { formatPrice } from '../../../../Charts/Functions'
import { Chart } from './Chart'

export function SavingsPerTipoItinerario ({ dbData = [] }) {
  const [tableData, setTableData] = useState([])

  const [year1 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()

  useEffect(() => {
    const elements = []

    dbData.forEach(({ NazConInt, QuantitaBiglietti, SommaTotaleBiglietto, SommaTariffaMinima, SommaTariffaUfficiale, Saving, MissSaving }) => {
      elements.push({
        NazConInt,
        QuantitaBiglietti,
        SommaTotaleBiglietto,
        SommaTariffaMinima,
        SommaTariffaUfficiale,
        Saving,
        MissSaving
      })
    })
    elements.push({
      NazConInt: 'Totale',
      QuantitaBiglietti: dbData.reduce((acc, { QuantitaBiglietti }) => acc + parseFloat(QuantitaBiglietti), 0),
      SommaTotaleBiglietto: dbData.reduce((acc, { SommaTotaleBiglietto }) => acc + parseFloat(SommaTotaleBiglietto), 0),
      SommaTariffaMinima: dbData.reduce((acc, { SommaTariffaMinima }) => acc + parseFloat(SommaTariffaMinima), 0),
      SommaTariffaUfficiale: dbData.reduce((acc, { SommaTariffaUfficiale }) => acc + parseFloat(SommaTariffaUfficiale), 0),
      Saving: dbData.reduce((acc, { Saving }) => acc + parseFloat(Saving), 0),
      MissSaving: dbData.reduce((acc, { MissSaving }) => acc + parseFloat(MissSaving), 0)
    })
    setTableData(elements)
  }, [dbData])

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fh fw>
        <ChartTitle
          title={`Saving Per Tipo Itinerario ${year1}`}
          // subTitle='La lista delle aree con il costo totale e la quantità per anno.'
        />
        <Table tableData={tableData} />
        <Chart data={dbData} />
      </Flex>
    </div>
  )
}

const Table = ({ tableData }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Nazionalità</th>
          <th>Quantità Biglietti</th>
          <th>Tariffa Applicata</th>
          <th>Tariffa Pubblica</th>
          <th>Tariffa Minima</th>
          <th>Saving</th>
          <th>MissSaving</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ NazConInt, QuantitaBiglietti, SommaTotaleBiglietto, SommaTariffaMinima, SommaTariffaUfficiale, Saving, MissSaving, SommaTotaleBiglietto2, SommaTariffaMinima2, SommaTariffaUfficiale2, Saving2, MissSaving2 }, key) =>
          <tr key={key} style={{ fontWeight: NazConInt === 'Totale' ? 'bold' : 'normal' }}>
            <td>{NazConInt}</td>
            <td>{QuantitaBiglietti}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(SommaTotaleBiglietto)}</td>
            <td>{formatPrice(SommaTariffaUfficiale)}</td>
            <td>{formatPrice(SommaTariffaMinima)}</td>
            <td>{formatPrice(Saving)}</td>
            <td>{formatPrice(MissSaving)}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
