import { TopFornitoriNoleggi } from './NoleggiPerFornitore'
import { SavingsPerMotivazione1 } from './SavingsPerMotivazione1'
import { SavingsPerTipoItinerario } from './SavingsPerTipoItinerario'
import { SavingsPerTipoTariffa } from './SavingsPerTipoTariffa'
import { SpesaHotelPerDestinazioni } from './SpesaHotelPerDestinazioni'
import { SpesaHotelPerHotel } from './SpesaHotelPerHotel'
import { SpesaMensile } from './SpesaMensile'
import { SpesaNoleggiPerDestinazioni } from './SpesaNoleggiPerDestinazioni'
import { SpesaPerArea } from './SpesaPerArea'
import { SpesaPerVettore } from './SpesaPerVettore'
import { SpesaRiemissioni } from './SpesaRiemissioni'
import { TariffaMediaPerTipoBiglietteriaClasse } from './TariffaMediaPerTipoBiglietteriaClasse'
import { TopDestinazioni } from './TopDestinazioni'
import { TopPassengersBiglietti } from './TopPassengersBiglietti'

const components = [
  {
    type: 'TariffaMediaPerTipoBiglietteriaClasse',
    element: <TariffaMediaPerTipoBiglietteriaClasse />
  },
  {
    type: 'SpesaPerArea',
    element: <SpesaPerArea />
  },
  {
    type: 'SpesaMensile',
    element: <SpesaMensile />
  },
  {
    type: 'TopDestinazioniVoli',
    element: <TopDestinazioni tipoBiglietteria='A' />
  }, {
    type: 'TopDestinazioniTreni',
    element: <TopDestinazioni tipoBiglietteria='F' />
  },
  {
    type: 'SpesaRiemissioniVoli',
    element: <SpesaRiemissioni tipoBiglietteria='A' />
  },
  {
    type: 'SpesaRiemissioniTreni',
    element: <SpesaRiemissioni tipoBiglietteria='F' />
  },
  {
    type: 'SpesaPerVettoreVoli',
    element: <SpesaPerVettore tipoBiglietteria='A' />
  },
  {
    type: 'SpesaPerVettoreTreni',
    element: <SpesaPerVettore tipoBiglietteria='F' />
  },
  {
    type: 'SpesaHotelPerDestinazioni',
    element: <SpesaHotelPerDestinazioni />
  },
  {
    type: 'SpesaPerHotel',
    element: <SpesaHotelPerHotel />
  },
  {
    type: 'TopFornitoriNoleggi',
    element: <TopFornitoriNoleggi />
  },
  {
    type: 'SpesaPerNoleggi',
    element: <SpesaNoleggiPerDestinazioni />
  },
  {
    type: 'SavingsPerTipoItinerario',
    element: <SavingsPerTipoItinerario />
  },
  {
    type: 'SavingsPerTipoTariffa',
    element: <SavingsPerTipoTariffa />
  },
  {
    type: 'SavingsPerMotivazione1',
    element: <SavingsPerMotivazione1 />
  },
  {
    type: 'TopPassengersBiglietti',
    element: <TopPassengersBiglietti />
  }
]

export { components }
