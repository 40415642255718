import { FC, invalidateQuery } from '../../../Services'

export const deleteReport = async (reportId) => {
  const res = await FC.service('reports').patch(reportId, { deleted: true })
  invalidateQuery(['reports', 'report'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}

export const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})
