import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Flex, Spinner } from '../../../Components'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const barOptions = (year1) => ({
  indexAxis: 'x',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Report Spesa per passeggero ' + year1
    }
  }
})

export function Chart ({ data = [] }) {
  const [barData, setBarData] = useState()

  const [year1 = ''] = data.map(({ Anno }) => Anno).filter((value, index) => data.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  useEffect(() => {
    if (!data?.length) return
    let labels = data.map(({ NomePasseggero }) => NomePasseggero).slice(0, 40)
    labels = labels.filter((value, index) => labels.indexOf(value) === index)
    setBarData({
      labels,
      datasets: [
        {
          label: year1,
          data: labels.map((l) => data?.find(({ NomePasseggero, Anno }) => NomePasseggero === l && Anno === year1)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ]
    })
  }, [data])

  if (!barData) return <Spinner />
  return (
    <Flex fw>
      <Bar options={barOptions(year1)} data={barData} />
    </Flex>
  )
}
