export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const search = Array.isArray(params) ? params : [params]
  const found = search.map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const tipoBiglietteriaOptions = [{ label: 'Tutto', value: '' }, { label: 'Voli', value: 'A' }, { label: 'Treni', value: 'F' }, { label: 'Varia', value: 'V' }]

// function that given a number returns a string with the number formatted with the thousands separator
export const formatNumber = (number) => {
  if (number === null || number === undefined) return ''
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
