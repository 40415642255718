import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../../Components'

export function ReportPagesTable ({ reportsList: [reportsList, setReportsList] }) {
  const BtnActions = ({ _id, ind: { rowIndex } }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex row>
        <Modal visible={modalVisible} header='Rimozione pagina' onHide={() => setModalVisible(false)}>
          <Flex fw>
            <Text value='Vuoi rimuovere questa pagina?' />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='bin' onClick={() => setModalVisible(false)} />
              <Button
                label='Conferma' icon='check' onClick={() => {
                  const _reportsList = [...reportsList]
                  _reportsList.splice(rowIndex, 1)
                  setReportsList(_reportsList)
                  setModalVisible(false)
                }}
              />
            </Flex>
          </Flex>
        </Modal>
        <Button style={{ marginLeft: 10 }} round tooltip='Elimina' icon='bin' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  return (
    <DataTable
      className='TableSignatures' value={reportsList} rows={20}
      onRowReorder={(e) => setReportsList(e.value)} reorderableRows
      paginator style={{ minWidth: 600 }} emptyMessage='Nessuna pagina inserita'
    >
      <Column rowReorder style={{ width: '3rem' }} />
      <Column field='category' header='Categoria' />
      <Column field='name' header='Tipo' />
      <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props, ind) => <BtnActions {...props} ind={ind} />} />
    </DataTable>
  )
}
