import { TariffaMediaPerTipoBiglietteriaClasse } from '../TariffaMediaPerTipoBiglietteriaClasse'
import { SpesaPerArea } from '../SpesaPerArea'
import { SpesaMensile } from '../SpesaMensile'
import { TopDestinazioni } from '../TopDestinazioni'
import { SpesaRiemissioni } from '../SpesaRiemissioni'
import { SpesaPerVettore } from '../SpesaPerVettore'
import { SpesaHotelPerDestinazioni } from '../SpesaHotelPerDestinazioni'
import { SpesaPerHotel } from '../SpesaPerHotel'
import { SpesaPerNoleggi } from '../SpesaPerNoleggi'
import { TopFornitoriNoleggi } from '../TopFornitoriNoleggi'
import { Button, Flex, Input, Modal, Spinner, Text } from '../../../Components'
import { ReportFilter } from '../ReportFilter'
import { useNavigate, useParams } from 'react-router-dom'
import { baseUrlPdfPrinter, getOrigin, saveReport, useReport } from './Functions'
import { SelectReport } from '../SelectReport'
import { useState } from 'react'
import { LocalStorage } from '../../../Services'
import { ReportPagesTable } from './ReportPagesTable'

export function ReportEditor () {
  const { reportId } = useParams()
  const navigate = useNavigate()
  const [report, setReport, isSuccess] = useReport(reportId)
  const [selectReportModal, setSelectReportModal] = useState(false)
  const { _id, reportsList = [], reportFilter = {}, name, description } = report || {}

  const save = async () => {
    const res = await saveReport(report)
    res?._id && navigate('/report/' + res?._id?.toString())
    return res
  }

  const print = async () => {
    const res = await save()
    if (!res?._id) return
    const { accessToken } = LocalStorage.get('user')
    const url = `${baseUrlPdfPrinter}/BIStats?jwt=${accessToken}&reportId=${reportId}&origin=${getOrigin()}`
    window.open(url)
  }

  if (!isSuccess || !report) return <Spinner />

  const isNew = _id === 'new'

  return (
    <Flex fw>
      <Text value='Crea Report' title bold size={30} />
      <Flex fw>
        <Modal onHide={() => setSelectReportModal(false)} visible={selectReportModal} style={{ width: '95%', height: '95%', backgroundColor: '#f5f5f5' }}>
          <SelectReport
            selectedReports={reportsList}
            onHide={() => setSelectReportModal(false)}
            filter={reportFilter}
            onSelect={(reportPage) => { setReport('reportsList', [...reportsList, reportPage]); setSelectReportModal(false) }}
          />
        </Modal>
        <ReportFilter
          onClickLoad={(year, months, clients, /* tipoBiglietteria */ filterClients) => setReport('reportFilter', { year, months, clients, /* tipoBiglietteria */ filterClients })}
          initialState={reportFilter} readOnly={_id !== 'new'}
        />
        <Input value={name} onChange={({ name }) => setReport('name', name)} label='Nome' id='name' style={{ marginBottom: 10, width: 600 }} />
        <Input multiline value={description} onChange={({ description }) => setReport('description', description)} placeholder='Descrizione' id='description' style={{ marginBottom: 10, width: 600 }} />
        {!isNew && (
          <>
            <Flex row style={{ minWidth: 600 }}>
              <Flex style={{ width: '33%' }} />
              <Flex style={{ width: '33%' }}>
                <Text center value='Elenco pagine' bold title size={22} />
              </Flex>
              <Flex style={{ width: '33%' }}>
                <Button label='Aggiungi' onClick={() => setSelectReportModal(true)} icon='plus' />
              </Flex>
            </Flex>
            <ReportPagesTable reportsList={[reportsList, (newReportList) => setReport('reportsList', newReportList)]} />
          </>)}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
          {!isNew && <Button label='Stampa' icon='print' style={{ marginRight: 10 }} onClick={print} />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export {
  TariffaMediaPerTipoBiglietteriaClasse, SpesaPerArea, SpesaMensile, TopDestinazioni, SpesaRiemissioni,
  SpesaPerVettore, SpesaHotelPerDestinazioni, SpesaPerHotel, SpesaPerNoleggi, TopFornitoriNoleggi
}
