import React from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button, Flex } from '../../../Components'
import { TextEditor } from '../ReportEditor/Functions'
import { ChartSavings } from './ChartSavings'
import { ChartMissSavings } from './ChartMissSavings'

export function SavingsPerTipoItinerario ({ tableData: [tableData, setTableData] }) {
  const onRowEditComplete = ({ newData, index }) => {
    const _products = [...tableData]
    _products[index] = newData
    setTableData(_products)
  }

  return (
    <Flex fw>
      <DataTable
        value={tableData} style={{ width: '100%' }} onRowEditComplete={onRowEditComplete} editMode='row'
        header='Saving per tipo itinerario' paginator rows={10} rowsPerPageOptions={[5, 10, 20]}
      >
        <Column field='NazConInt' header='Nazionalità' />
        <Column field='QuantitaBiglietti' header='Quantità Biglietti' editor={(options) => TextEditor(options)} />
        <Column field='SommaTotaleBiglietto' header='Tariffa Applicata' editor={(options) => TextEditor(options)} />
        <Column field='SommaTariffaMinima' header='Tariffa Minima' editor={(options) => TextEditor(options)} />
        <Column field='SommaTariffaUfficiale' header='Tariffa Pubblica' editor={(options) => TextEditor(options)} />
        <Column field='Saving' header='Saving' editor={(options) => TextEditor(options)} />
        <Column field='MissSaving' header='Miss saving' editor={(options) => TextEditor(options)} />
        <Column rowEditor bodyStyle={{ textAlign: 'center' }} />
        <Column body={(rowData, { rowIndex }) => <Button round icon='bin' onClick={() => setTableData(tableData.filter((_, i) => i !== rowIndex))} />} bodyStyle={{ textAlign: 'center' }} />
      </DataTable>
      <hr style={{ width: '100%', border: '1px solid black', margin: 20 }} />
      <ChartSavings data={tableData} />
      <ChartMissSavings data={tableData} />
    </Flex>
  )
}
