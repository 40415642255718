import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { Flex } from '../../../../Components'

const colors = ['rgba(255, 0, 0, 0.5)', 'rgba(0, 255, 0, 0.5)', 'rgba(0, 0, 255, 0.5)', 'rgba(255, 255, 0, 0.5)', 'rgba(255, 0, 255, 0.5)', 'rgba(0, 255, 255, 0.5)', 'rgba(128, 0, 0, 0.5)', 'rgba(0, 128, 0, 0.5)', 'rgba(0, 0, 128, 0.5)', 'rgba(128, 128, 0, 0.5)', 'rgba(255, 165, 0, 0.5)', 'rgba(128, 0, 128, 0.5)', 'rgba(128, 128, 128, 0.5)', 'rgba(255, 192, 203, 0.5)', 'rgba(0, 128, 128, 0.5)']

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

const barOptions = (year1, year2) => ({
  indexAxis: 'x',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    legend: { position: 'right' },
    title: {
      display: false,
      text: `Spesa complessiva area ${year1} vs ${year2}`
    }
  }
})

const pieOptions = (year) => ({
  responsive: true,
  plugins: {
    legend: { position: 'bottom' },
    title: {
      display: false,
      text: `Incidenza spesa ${year}`
    }
  }
})

export function Chart ({ data = [] }) {
  const [barData, setBarData] = useState()
  const [pieData, setPieData] = useState()

  const [year1 = '', year2 = ''] = data.map(({ Anno }) => Anno).filter((value, index) => data.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  useEffect(() => {
    if (!data?.length) return
    let labels = data.map(({ Area }) => Area)
    labels = labels.filter((value, index) => labels.indexOf(value) === index).sort()
    setBarData({
      labels,
      datasets: [
        {
          label: year1,
          data: labels.map((l) => data?.find(({ Area, Anno }) => Area === l && Anno === year1)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: year2,
          data: labels.map((l) => data?.find(({ Area, Anno }) => Area === l && Anno === year2)?.SpesaComplessiva || '0'),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    })
    const pieChartData = labels.map((l) => data?.find(({ Area, Anno }) => Area === l && Anno === year1)?.SpesaComplessiva || '0')
    setPieData({
      labels,
      datasets: [
        {
          label: `Spesa complessiva ${year1}`,
          data: pieChartData,
          backgroundColor: pieChartData.map((_, i) => colors[i]),
          borderWidth: 2
        }]
    })
  }, [data])

  if (!barData || !pieData) return null
  return (
    <Flex row fw>
      <Flex style={{ width: '50%', paddingLeft: 80 }} as>
        <Flex style={{ width: 800, height: 650 }}>
          <Bar options={barOptions(year1, year2)} data={barData} />
        </Flex>
      </Flex>
      <Flex style={{ width: '50%', paddingRight: 80 }} ae>
        <Flex style={{ width: 500, height: 500 }}>
          <Pie options={pieOptions(year1)} data={pieData} />
        </Flex>
      </Flex>
    </Flex>
  )
}
