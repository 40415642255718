import React, { useState } from 'react'
import { Button, Flex, Input, Modal, Text, SearchClient } from '../../../Components'
import { Slider } from 'primereact/slider'
import { Checkbox } from 'primereact/checkbox'
import { ClientsTable } from './ClientsTable'

// const tipoBiglietteriaOptions = [{ label: 'Tutto', value: '' }, { label: 'Aerea', value: 'A' }, { label: 'Ferroviaria', value: 'F' }, { label: 'Varia', value: 'V' }]
const monthsOpt = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((y) => ({ label: y, value: y }))

export const ReportFilter = ({ onClickLoad, noYear, noMonths, noClients, /* noTipoBiglietteria */ initialState, readOnly = false }) => {
  const [year, setYear] = useState(initialState?.year || new Date().getFullYear())
  const [months, setMonths] = useState(initialState?.months || [0, 11])
  const [clients, setClients] = useState(initialState?.clients || [])
  const [filterClients, setFilterClients] = useState(initialState?.filterClients || false)
  // const [tipoBiglietteria, setTipoBiglietteria] = useState(initialState?.tipoBiglietteria || '')
  const [modalFilterVisible, setModalFilterVisible] = useState(false)

  const onSetFilter = ({ year, months, clients, /* tipoBiglietteria */ filterClients }) => {
    setYear(year)
    setMonths(months)
    setClients(clients)
    // setTipoBiglietteria(tipoBiglietteria)
    setFilterClients(filterClients)
    setModalFilterVisible(false)
    onClickLoad(year, months, clients, /* tipoBiglietteria */ filterClients)
  }

  return (
    <>
      <Flex row js style={{ marginBottom: 10 }}>
        {!readOnly && <Button label='Gestisci filtri' onClick={() => setModalFilterVisible(true)} icon='tool' />}
        <Text style={{ margin: 10 }} value='Riepilogo Filtri:' />
        {!noYear && <Text style={{ margin: 10 }} value={`Anno: ${year}`} />}
        {!noMonths && <Text style={{ margin: 10 }} value={`Mesi: ${monthsOpt[months[0]]} - ${monthsOpt[months[1]]}`} />}
        {!noClients && <Text style={{ margin: 10 }} value={`Clienti: ${filterClients ? `${clients?.length} selezionati` : 'Tutti'}`} />}
        {/* {!noTipoBiglietteria && <Text style={{ margin: 10 }} value={`Tipo Biglietteria: ${tipoBiglietteriaOptions.find((t) => t.value === tipoBiglietteria)?.label}`} />} */}
      </Flex>
      <Modal
        header='Filtri Ricerca' style={{ width: '90%', maxHeight: '90%', backgroundColor: '#f5f5f5' }}
        noDismissable hideClose visible={modalFilterVisible} onHide={() => setModalFilterVisible(false)}
      >
        <SelectMonthYearClient
          applyFilter={onSetFilter} year={year} months={months} clientsList={clients} // tipoBiglietteria={tipoBiglietteria}
          noYear={noYear} noMonths={noMonths} noClients={noClients} // noTipoBiglietteria={noTipoBiglietteria}
          filterClients={filterClients}
        />
      </Modal>
    </>
  )
}

const SelectMonthYearClient = ({
  applyFilter = () => { },
  // tipoBiglietteria: tipoBiglietteriaState = '',
  year: yearState = '',
  months: monthsState = [],
  clientsList: clientsListState = [],
  filterClients: filterClientsState = false,
  noClients = false,
  noYear = false,
  noMonths = false,
  noTipoBiglietteria = false
}) => {
  // const [tipoBiglietteria, setTipoBiglietteria] = useState(tipoBiglietteriaState)
  const [year, setYear] = useState(yearState)
  const [months, setMonths] = useState(monthsState)
  const [clientsList, setClientsList] = useState(clientsListState)
  const [filterClients, setFilterClients] = useState(filterClientsState)

  return (
    <Flex fw fh style={{ backgroundColor: '#f5f5f5', borderRadius: 5 }}>
      <Flex row fh fw>
        {/* {!noTipoBiglietteria && <TipoBiglietteriaFilter tipoBiglietteria={[tipoBiglietteria, setTipoBiglietteria]} />} */}
        {!noYear && <YearFilter year={[year, setYear]} />}
        {!noMonths && <MonthsFilter months={[months, setMonths]} />}
        {!noClients && (
          <Flex row style={{ margin: 10 }}>
            <Text value='Filtra per clienti' bold style={{ marginRight: 10 }} />
            <Checkbox checked={filterClients} onChange={({ checked }) => setFilterClients(checked)} />
          </Flex>)}
        <Flex style={{ width: 200 }}>
          <Button label='Applica Filtri' icon='lens' onClick={() => applyFilter({ year, months, clients: clientsList, /* tipoBiglietteria */ filterClients })} />
        </Flex>
      </Flex>
      <ClientsFilter clientsList={[clientsList, setClientsList]} filterClients={filterClients} />
    </Flex>
  )
}

const YearFilter = ({ year: [year = '', setYear = () => {}] = [] }) => (
  <Flex style={{ margin: 10 }}>
    <Text value='Anno' bold />
    <Input id='data' dropdown options={years} value={year} onChange={({ data }) => setYear(data)} style={{ margin: '0px 10px' }} />
  </Flex>
)

const MonthsFilter = ({ months: [months = [], setMonths = () => {}] = [] }) => (
  <Flex style={{ margin: 10, padding: 20, paddingTop: 5, backgroundColor: 'white', borderRadius: 20 }}>
    <Text value='Mesi' bold style={{ margin: 5 }} />
    <Flex row fw jb style={{ marginBottom: 10 }}>
      <Text value={`Da ${monthsOpt[months[0]]}`} />
      <Text value={`A ${monthsOpt[months[1]]}`} />
    </Flex>
    <Slider value={months} onChange={({ value }) => value[0] <= value[1] && setMonths(value)} range style={{ width: 200 }} min={0} max={11} step={1} />
  </Flex>
)

/* const TipoBiglietteriaFilter = ({ tipoBiglietteria: [tipoBiglietteria = '', setTipoBiglietteria = () => {}] = [] }) => (
  <Flex style={{ margin: 10 }}>
    <Text value='Tipo biglietteria' bold />
    <Input
      value={tipoBiglietteria} onChange={({ data }) => setTipoBiglietteria(data)}
      dropdown id='data' options={tipoBiglietteriaOptions} style={{ margin: '0px 10px' }}
    />
  </Flex>
) */

const ClientsFilter = ({ clientsList: [clientsList = [], setClientsList = () => {}] = [], filterClients }) => {
  const [modalAddVisible, setModalAddVisible] = useState(false)

  const onSelectClients = (clients) => {
    setClientsList([...clientsList, ...clients.filter((client) => {
      const clientAlreadyInList = clientsList.find((c) => c?.codCliente12 === client?.codCliente12)
      if (clientAlreadyInList) {
        window.growl.show({ severity: 'error', summary: 'Attenzione', detail: `Il cliente ${client?.codCliente12} è già stato aggiunto` })
        return false
      }
      return true
    })])
  }

  if (!filterClients) return null
  return (
    <Flex style={{ margin: 10 }}>
      <Modal
        header='Ricerca e selezione cliente' style={{ width: '80%', maxHeight: '100%' }}
        noDismissable hideClose visible={modalAddVisible} onHide={() => setModalAddVisible(false)}
      >
        <Flex fw fh style={{ backgroundColor: 'rgba(231,231,231)', padding: 20, overflow: 'auto', borderRadius: 20 }}>
          <SearchClient onSelectClients={onSelectClients} hideModal={() => setModalAddVisible(false)} />
        </Flex>
      </Modal>
      <Flex fw row style={{ marginTop: 20 }}>
        <Flex style={{ marginLeft: '25%', width: '50%' }}>
          <Text value='Clienti' bold title size={22} />
        </Flex>
        <Flex ae style={{ width: '25%' }}>
          <Button label='Aggiungi' icon='plus' onClick={() => setModalAddVisible(true)} />
        </Flex>
      </Flex>
      <ClientsTable clientsList={[clientsList, setClientsList]} />
    </Flex>
  )
}
