import React from 'react'
import { Flex, Text } from '../../../../Components'

// const tipoBiglietteriaOptions = [{ label: 'Tutto', value: '' }, { label: 'Aerea', value: 'A' }, { label: 'Ferroviaria', value: 'F' }, { label: 'Varia', value: 'V' }]
const monthsOpt = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

export function FirstPage ({ report }) {
  const { reportFilter } = report
  const { year, months, clients, /* tipoBiglietteria */ filterClients } = reportFilter || {}

  const showClientsList = filterClients && clients?.length > 0

  const { design: { logo } } = report

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fh fw as js>
        <Flex style={{ width: '50%', height: '20%' }}>
          <img src={logo} alt='logo' style={{ width: '60%', maxHeight: '80%', objectFit: 'contain' }} />
        </Flex>
        <Flex fw style={{ backgroundColor: 'rgb(27,32,80)', height: 80, paddingRight: 50 }} ae>
          <Text value='Analisi Spesa Travel' color='white' size={40} bold />
        </Flex>
        <Flex fw ae style={{ marginTop: 50 }}>
          <FilterElementWrapper label='Anno'>
            <Flex style={{ height: 50, width: 400 }}>
              <Text center value={year} size={25} bold style={{ width: '100%' }} />
            </Flex>
          </FilterElementWrapper>
          <FilterElementWrapper label='Periodo'>
            <Flex style={{ height: 50, width: 400 }}>
              <Text center value={`${monthsOpt[months[0]]} - ${monthsOpt[months[1]]}`} size={25} bold style={{ width: '100%' }} />
            </Flex>
          </FilterElementWrapper>
          {showClientsList &&
            <FilterElementWrapper label='Clienti'>
              <Flex style={{ width: 400, height: 550, paddingTop: 10 }} js wrap>
                {(clients?.reduce((acc, curr, i) => {
                  if (!(i % 10)) acc.push(clients.slice(i, i + 10))
                  return acc
                }, [])
                  .map((arr, i) => (arr
                    ?.map(({ codiceFiscale, nome, cognome, ragioneSociale }) =>
                      <Text value={(nome || cognome) ? (nome + ' ' + cognome) : ragioneSociale} size={20} bold key={codiceFiscale} />
                    ))))}
              </Flex>
            </FilterElementWrapper>}
        </Flex>
      </Flex>
    </div>
  )
}

const FilterElementWrapper = ({ children, label }) => (
  <Flex style={{ width: '50%' }}>
    <Flex style={{ backgroundColor: 'rgb(27,32,80)', height: 50, width: 400 }}>
      <Text center value={label} color='white' size={25} bold style={{ width: '100%' }} />
    </Flex>
    {children}
  </Flex>
)
