import React from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button, Flex } from '../../../Components'
import { Chart } from './Chart'
import { TextEditor } from '../ReportEditor/Functions'

export function SpesaPerVettore ({ tableData: [tableData, setTableData] }) {
  const onRowEditComplete = ({ newData, index }) => {
    const _products = [...tableData]
    _products[index] = newData
    setTableData(_products)
  }

  const [year1 = '', year2 = ''] = tableData.map(({ Anno }) => Anno).filter((value, index) => tableData.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  return (
    <Flex fw>
      <DataTable
        value={tableData} style={{ width: '100%' }} onRowEditComplete={onRowEditComplete} editMode='row'
        header={`Spesa per vettore ${year1} vs ${year2}`} paginator rows={10} rowsPerPageOptions={[5, 10, 20]}
      >
        <Column field='Anno' header='Anno' />
        <Column field='Vettore' header='Compagnia' />
        <Column field='SpesaComplessiva' header='Somma totale netto' editor={(options) => TextEditor(options)} />
        <Column rowEditor bodyStyle={{ textAlign: 'center' }} />
        <Column body={(rowData, { rowIndex }) => <Button round icon='bin' onClick={() => setTableData(tableData.filter((_, i) => i !== rowIndex))} />} bodyStyle={{ textAlign: 'center' }} />
      </DataTable>
      <hr style={{ width: '100%', border: '1px solid black', margin: 20 }} />
      <Chart data={tableData} />
    </Flex>
  )
}
