import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Spinner } from '../../../Components'
import { tipoBiglietteriaOptions } from '../../../Services/Functions'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const barOptions = (year1, year2, tipoBiglietteria) => ({
  indexAxis: 'y',
  elements: { bar: { borderWidth: 2 } },
  responsive: true,
  plugins: {
    legend: { position: 'right' },
    title: {
      display: true,
      text: `Report Spesa ${tipoBiglietteriaOptions.find(({ value }) => value === tipoBiglietteria)?.label || 'Tutto'} ${year1} vs ${year2}`
    }
  }
})

export function Chart ({ data, tipoBiglietteria }) {
  const [chartData, setChartData] = useState()

  const [year1 = '', year2 = ''] = data.map(({ Anno }) => Anno).filter((value, index) => data.map(({ Anno }) => Anno).indexOf(value) === index).sort().reverse()

  useEffect(() => {
    let labels = data.filter(({ TipoBiglietteria }) => TipoBiglietteria === tipoBiglietteria).map(({ NazConInt, Classe }) => NazConInt + ' - ' + Classe)
    labels = labels.filter((value, index) => labels.indexOf(value) === index).sort()
    setChartData({
      labels,
      datasets: [
        {
          label: year1,
          data: labels.map((l) => data?.find(({ NazConInt, Classe, Anno }) => NazConInt + ' - ' + Classe === l && Anno === year1)?.PrezzoMedio || '0'),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        },
        {
          label: year2,
          data: labels.map((l) => data?.find(({ NazConInt, Classe, Anno }) => NazConInt + ' - ' + Classe === l && Anno === year2)?.PrezzoMedio || '0'),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)'
        }
      ]
    })
  }, [data])

  if (!chartData) return <Spinner />
  return (
    <Bar options={barOptions(year1, year2, tipoBiglietteria)} data={chartData} />
  )
}
