import React, { useState, useEffect } from 'react'
import { ChartTitle } from '../Components'
import { Flex } from '../../../../Components'
import { tipoBiglietteriaOptions } from '../../../../Services/Functions'
import { formatPrice } from '../../../../Charts/Functions'

export function SpesaRiemissioni ({ dbData = [], tipoBiglietteria }) {
  const [tableDataYear1, setTableDataYear1] = useState([])
  const [tableDataYear2, setTableDataYear2] = useState([])

  const [year1 = '', year2 = ''] = dbData?.map(({ Anno }) => Anno)?.filter((value, index) => dbData.map(({ Anno }) => Anno)?.indexOf(value) === index).sort().reverse()

  useEffect(() => {
    const NazConIntOptions = dbData.map(({ NazConInt }) => NazConInt).filter((value, index) => dbData.map(({ NazConInt }) => NazConInt).indexOf(value) === index).sort()
    const dataYear1 = NazConIntOptions.map(NazConInt => ({ NazConInt }))
    const dataYear2 = NazConIntOptions.map(NazConInt => ({ NazConInt }))

    dbData.forEach(({ NazConInt, Riemissione, SpesaComplessiva, Quantita, Anno }) => {
      if (Riemissione === 'Y') {
        if (Anno === year1) {
          const index = dataYear1.findIndex(({ NazConInt: NazConInt2 }) => NazConInt2 === NazConInt)
          dataYear1[index].CostoRiemissione = SpesaComplessiva
          dataYear1[index].TicketModificati = Quantita
        }
        if (Anno === year2) {
          const index = dataYear2.findIndex(({ NazConInt: NazConInt2 }) => NazConInt2 === NazConInt)
          dataYear2[index].CostoRiemissione = SpesaComplessiva
          dataYear2[index].TicketModificati = Quantita
        }
      }
      if (Riemissione === 'N') {
        if (Anno === year1) {
          const index = dataYear1.findIndex(({ NazConInt: NazConInt2 }) => NazConInt2 === NazConInt)
          dataYear1[index].Spesa1Ticket = SpesaComplessiva
          dataYear1[index].Nr1Tkt = Quantita
        }
        if (Anno === year2) {
          const index = dataYear2.findIndex(({ NazConInt: NazConInt2 }) => NazConInt2 === NazConInt)
          dataYear2[index].Spesa1Ticket = SpesaComplessiva
          dataYear2[index].Nr1Tkt = Quantita
        }
      }
    })
    const calculateOtherData = (item) => {
      const { CostoRiemissione, TicketModificati, Spesa1Ticket, Nr1Tkt } = item
      item['%SulCosto'] = (CostoRiemissione / Spesa1Ticket * 100).toFixed(2)
      item['%SulNrTkt'] = (TicketModificati / Nr1Tkt * 100).toFixed(2)
      item.CostoMedioRiemissione = (CostoRiemissione / TicketModificati).toFixed(2)
    }
    dataYear1.sort((a, b) => a.CostoRiemissione - b.CostoRiemissione).forEach(calculateOtherData)
    dataYear2.sort((a, b) => a.CostoRiemissione - b.CostoRiemissione).forEach(calculateOtherData)
    setTableDataYear1(dataYear1)
    setTableDataYear2(dataYear2)
  }, [dbData])

  return (
    <>
      <div style={{ width: '100vw', height: '100vh' }}>
        <Flex fh fw>
          <ChartTitle
            title={`${tipoBiglietteriaOptions.find(({ value }) => value === tipoBiglietteria)?.label} - Analisi Spesa Riemissioni ${year1} vs ${year2}`}
            subTitle='Analisi della spesa per riemissione dei biglietti per anno.'
          />
          <Table tableData={tableDataYear1} year={year1} />
          <div style={{ height: 50 }} />
          <Table tableData={tableDataYear2} year={year2} />
        </Flex>
      </div>
    </>
  )
}

const Table = ({ tableData, year }) => {
  return (
    <table className='tableChart'>
      <thead>
        <tr>
          <th colSpan={8} style={{ textAlign: 'center' }}>{year}</th>
        </tr>
        <tr style={{ fontWeight: 'bold' }}>
          <th>Tipo Biglietteria</th>
          <th>Spesa 1° Ticket</th>
          <th>Nr 1° Tkt</th>
          <th>Costo Riemissioni</th>
          <th>Ticket Modificati</th>
          <th>% sul costo</th>
          <th>% sul nr tkt</th>
          <th>Costo Medio Riemissione</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(({
          NazConInt, Spesa1Ticket, Nr1Tkt, CostoRiemissione, TicketModificati, '%SulCosto': PercentualeSulCosto, '%SulNrTkt': PercentualeSulNrTkt, CostoMedioRiemissione
        }, key) =>
          <tr key={key}>
            <td>{NazConInt}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{formatPrice(Spesa1Ticket)}</td>
            <td style={{ backgroundColor: '#80a6dc72', color: 'rgb(5, 15, 102)' }}>{Nr1Tkt}</td>
            <td>{formatPrice(CostoRiemissione)}</td>
            <td>{TicketModificati}</td>
            <td style={{ backgroundColor: '#80dc7282', color: '#0f6e0f' }}>{PercentualeSulCosto} %</td>
            <td style={{ backgroundColor: '#80dc7282', color: '#0f6e0f' }}>{PercentualeSulNrTkt} %</td>
            <td>{formatPrice(CostoMedioRiemissione)}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
