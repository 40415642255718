import axios from 'axios'
import { getUrlParams } from '../Services/Functions'
import { LocalStorage } from '../Services'

const formatter = new Intl.NumberFormat('it-TI', { style: 'currency', currency: 'EUR' })

export const months = {
  '01_Gennaio': 'January',
  '02_Febbraio': 'February',
  '03_Marzo': 'March',
  '04_Aprile': 'April',
  '05_Maggio': 'May',
  '06_Giugno': 'June',
  '07_Luglio': 'July',
  '08_Agosto': 'August',
  '09_Settembre': 'September',
  '10_Ottobre': 'October',
  '11_Novembre': 'November',
  '12_Dicembre': 'December'
}

export const hostNameMap = {
  // 'admin.apptour.travel': 'https://api.apptour.travel',
  // 'devstaging.apptour.travel': 'https://staging.apptour.travel',
  localhost: 'http://localhost:3000/',
  'host.docker.internal': 'http://host.docker.internal:3000/',
  '192.168.0.71': 'http://192.168.0.71:12201',
  '192.168.0.68': 'http://192.168.0.68:12001',
  'bistats.siap.cloud': 'https://bistatsapi.siap.cloud'
}

export const Api = () => axios.create({
  baseURL: hostNameMap[window.location.hostname],
  timeout: 10000,
  headers: { Authorization: getUrlParams('jwt') || LocalStorage.get('user')?.accessToken }
})

export const formatPrice = (price) => formatter.format(parseFloat(price) || 0)

export const getQueryParams = () => {
  const month = getUrlParams('month') || new Date().getMonth()
  const year = getUrlParams('year') || new Date().getFullYear()
  const clientCode = getUrlParams('clientCode')
  const shipOwnerCode = getUrlParams('shipOwnerCode')
  return { ...(clientCode ? { clientCode } : {}), ...(shipOwnerCode ? { shipOwnerCode } : {}), month, year }
}

// Funzione che crea n sottoarray di lunghezza limit dato un array iniziale.
// Esempio [1,2,3,4, ...., 28] => [[1,2,3,..., 24],  [25, 26, 27 ,28]]
export const chunkArray = (arr, limit = 24) => {
  const res = [] // array di risultati
  let i = 0 // indice di partenza

  while (i < arr.length) {
  // prendiamo i 24 elementi successivi all'indice i
    const tempArray = arr.slice(i, i + limit)
    // aggiungiamo il sottoarray al nostro array di risultati
    res.push(tempArray)
    // incrementiamo l'indice di partenza di 24
    i += limit
  }

  return res
}

export const repeatArrayElements = (array, y) => {
  const repeatedArray = []

  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < y; j++) {
      repeatedArray.push(array[i])
    }
  }

  return repeatedArray
}

export const raggruppaArray = (array) => {
  const raggruppamenti = []
  let gruppoCorrente = []

  array.forEach(subArray => {
    if (gruppoCorrente.length < 3) {
      gruppoCorrente.push(subArray)
    } else {
      raggruppamenti.push(gruppoCorrente)
      gruppoCorrente = [subArray]
    }
  })

  if (gruppoCorrente.length > 0) {
    raggruppamenti.push(gruppoCorrente)
  }

  return raggruppamenti
}
